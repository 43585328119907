import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { isAdmin } from '../../route/admin'

const searchCourses = (query) => ({
  type: 'SEARCH_COURSES',
  query,
})

class Menu extends React.Component {
  constructor(prop) {
    super(prop)
    this.state = { query: '' }
  }

  handleChange = (event) => {
    this.setState({ query: event.target.value })
  }

  handleSearch = (event) => {
    event.preventDefault()
    this.props.dispatch(searchCourses(this.state.query))
  }

  onLogin = (event) => {
    event.preventDefault()
    this.props.dispatch({ type: 'LOGIN' })
  }

  onLogout = (event) => {
    event.preventDefault()
    this.props.dispatch({ type: 'LOGOUT' })
  }

  renderAuth() {
    const { user } = this.props
    const login = (
      <Nav.Link as={Link} to="/login" onClick={this.onLogin}>
        Login
      </Nav.Link>
    )
    const logout = (user) => (
      <NavDropdown
        title={<img src={user.photoURL} className="user-photo-small" />}
        id="nav-dropdown"
        alignRight
      >
        <Navbar.Text href="#" className="user-info">
          <img src={user.photoURL} className="user-photo-large" />
          <br />
          <span className="user-name">{user.displayName}</span>
          <br />
          <span className="user-email">{user.email}</span>
        </Navbar.Text>
        <NavDropdown.Item href="/logout" title="" onClick={this.onLogout}>
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    )

    return user ? logout(user) : login
  }

  render() {
    return (
      <Navbar
        bg="primary"
        variant="dark"
        expand="lg"
        sticky="top"
        className="menu"
      >
        <div className="container">
          <Navbar.Brand href="/">
            <img src="/logo.png" alt="Discgolf" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              {this.props.games && (
                <Nav.Link as={Link} to="/rounds">
                  Rounds
                </Nav.Link>
              )}
              {this.props.players && (
                <Nav.Link as={Link} to="/players">
                  Players
                </Nav.Link>
              )}
              {isAdmin(this.props.user) && (
                <Nav.Link as={Link} to="/admin">
                  Admin
                </Nav.Link>
              )}
              <NavDropdown title="Help" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/faq">
                  FAQ
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/privacy">
                  Privacy Policy
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="https://pdga.com" target="_blank">
                  PDGA <i className="bi-box-arrow-up-right"></i>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="mailto:contact.codeunlimited+discgolf@gmail.com?subject=DiscGolf Help"
                  target="_blank"
                >
                  Contact <i className="bi-envelope"></i>
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="mailto:contact.codeunlimited+discgolf@gmail.com?subject=DiscGolf Feedback"
                  target="_blank"
                >
                  Feedback <i className="bi-envelope"></i>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="https://codeunlimited.se"
                  target="_blank"
                >
                  CodeUnlimited <i className="bi-box-arrow-up-right"></i>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <Form inline onSubmit={this.handleSearch}>
                <FormControl
                  type="text"
                  placeholder="Search"
                  value={this.state.query}
                  onChange={this.handleChange}
                  className="mr-sm-2"
                />
                <Button
                  variant="outline-success"
                  type="submit"
                  disabled={this.state.query.length < 2}
                >
                  Search
                </Button>
              </Form>
              {this.renderAuth()}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    )
  }
}

const mapStateToProps = (state) => {
  const { players, courses, games } = state.user.data || {}
  return {
    user: state.auth.user,
    players,
    courses,
    games,
  }
}

export default connect(mapStateToProps)(Menu)
