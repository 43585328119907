import React from 'react'
import { connect } from 'react-redux'
import converter from 'length-distance-converter'

const toggleDistanceFormat = () => ({
  type: 'TOGGLE_DISTANCE_FORMAT',
})

const postFixMap = {
  meter: `m`,
  feet: `"`,
  yard: `yd`,
}

class Distance extends React.Component {
  renderDistance = () => {
    const { distance, distanceFormat } = this.props

    switch (distanceFormat) {
      case 'meter':
        return distance
      case 'feet':
        return converter.metersToFeet(distance)
      case 'yard':
        return converter.metersToYard(distance)
    }
  }

  renderPostFix = () => {
    const { addPostfix, distanceFormat, spacePostfix } = this.props
    if (!addPostfix) return ''

    return (spacePostfix ? ' ' : '') + postFixMap[distanceFormat]
  }

  render() {
    const { distance } = this.props

    if (!distance) return null

    return (
      <span
        className="distance"
        onClick={() => this.props.dispatch(toggleDistanceFormat())}
      >
        {Math.round(this.renderDistance()).toLocaleString() +
          this.renderPostFix()}
      </span>
    )
  }
}

Distance.defaultProps = {
  distanceFormat: 'meter',
  addPostfix: true,
  spacePostfix: true,
}

const mapStateToProps = (state) => {
  return {
    distanceFormat: state.distanceFormat,
  }
}

export default connect(mapStateToProps)(Distance)
