import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import GroupsTable from '../../route/country/GroupsTable'

const closeSearchResults = () => ({
  type: 'CLEAR_SEARCH_RESULTS',
})

class SearchResults extends Component {
  onClose = () => {
    this.props.dispatch(closeSearchResults())
  }

  onCourseGroupClicked = (group) => {
    console.log('Click group', group, this.props.history)
    this.props.history.push(`/course/${group.id}`)
    this.onClose()
  }

  render() {
    const results = this.props.search.results
    if (!results || results.length === 0) return null

    return (
      <Modal
        show={!!results}
        onHide={this.onClose}
        dialogClassName="search-result"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Search results</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <GroupsTable
            groups={results}
            onCourseGroupClicked={this.onCourseGroupClicked}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={this.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

SearchResults.defaultProps = {
  search: {
    results: null,
    error: null,
  },
}

const mapStateToProps = (state) => {
  return {
    search: state.search,
  }
}

export default connect(mapStateToProps)(withRouter(SearchResults))
