const LoginMiddleware = (store) => (next) => (action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { user } = action

    const isLoggedIn = user && user.emailVerified && user.email
    if (isLoggedIn) {
      store.dispatch({
        type: 'GET_ACCOUNT_UUID',
        payload: {
          request: {
            url: `/v3/account`,
          },
        },
      })

      store.dispatch({
        type: 'LOAD_USER_DATA',
        payload: {
          request: {
            url: `/v3/synch/0`,
          },
        },
      })
    }
  }

  return next(action)
}

export default LoginMiddleware
