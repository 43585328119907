import React from 'react'
import { withRouter } from 'react-router-dom'
import Page from '../../component/page'
import RegionChart from './RegionChart'

function Region(props) {
  return (
    <Page>
      <RegionChart region={props.match.params[0]} />
    </Page>
  )
}

export default withRouter(Region)
