import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Scorecard from './Scorecard'

const loadCourse = (id) => ({
  type: 'LOAD_COURSE',
  payload: {
    request: {
      url: `/v2/course/${id}`,
    },
  },
})

class PrintableScorecard extends React.Component {
  componentDidMount() {
    const { courseId } = this.props.match.params

    const course = this.props.course.data

    if (course === null || course.id !== courseId) {
      this.props.dispatch(loadCourse(courseId))
    }
  }

  render() {
    const course = this.props.course.data
    if (course) {
      return (
        <div className="printable-scorecard">
          <Scorecard course={course} />
        </div>
      )
    }

    return <h1>Loading course...</h1>
  }
}

const mapStateToProps = (state) => {
  return {
    course: state.course,
  }
}

export default connect(mapStateToProps)(withRouter(PrintableScorecard))
