import Menu from '../menu'
import Footer from '../footer'
import SearchResults from './SearchResults'

function Page(props) {
  return (
    <div className="page">
      <Menu />
      <SearchResults />
      <div className={`page-content ${props.className}`}>{props.children}</div>
      <Footer />
    </div>
  )
}

export default Page
