import Page from '../../component/page'

function PrivacyPolicy() {
  return (
    <Page className="container">
      <div className="row">
        <div className="col-xs-12">
          <h1>Welcome to the DiscGolf Privacy Policy</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p>
            Here is a short overview of how we use your information in the
            DiscGolf products
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3 col-xs-12">
          <h4 className="overview-title">
            <i className="bi bi-lock"></i> Account
          </h4>
          <b>Email address</b> - is used to associate your players, games and
          courses with you
        </div>
        <div className="col-sm-3 col-xs-12">
          <h4 className="overview-title">
            <i className="bi bi-person"></i> Contacts
          </h4>
          <b>Pick contacts</b> - to create players in the app
        </div>
        <div className="col-sm-3 col-xs-12">
          <h4 className="overview-title">
            <i className="bi bi-pin-angle"></i> Location
          </h4>
          <b>GPS</b> - use location to create/view/locate course maps
        </div>
        <div className="col-sm-3 col-xs-12">
          <h4 className="overview-title">
            <i className="bi bi-camera"></i> Camera
          </h4>
          <b>Photo</b> - create player avatars
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <h2>Information we collect</h2>

          <p>
            We collect information to provide better services to all of our
            users - from figuring out basic stuff like which region to show
            courses in, to more complex things like how our apps are used and
            what might make them crash.
          </p>

          <h3>We collect information in the following ways:</h3>

          <h4>Information you give us</h4>

          <p>
            For example, course data you provide is shared openly with all users
            of our apps unless explicitly set as "Private" course data. Your
            contacts can be used to create players if you want to and may be
            stored on both your device(s) and our servers.
          </p>

          <h5>Account</h5>

          <p>
            We use your primary email to associate your players, games and
            courses with you. Even while you are not signed in, any course you
            create will also be logged to our servers with your primary email
            address. If you log in to the app we will associate the email
            account you've signed in with to your data. Once you are signed in
            your data can also be backed up on our servers and will be stored
            with your account which is identified by your email address. We do
            not see, nor handle your password in any way as that is securely
            taken care of by Google services.
          </p>

          <h5>Contacts</h5>

          <p>
            We allow you to create players in the app from your list of
            contacts. We do not access nor use your contacts in any other way.
          </p>

          <h5>Location</h5>

          <p>
            You can use your location to create course maps, view course maps
            and locate course maps. Your location can be tracked in the app to
            show you your location on a map and to display distances to hole
            tee/baket and to calculate throwing distances. You may also use you
            location to track disc throws.
          </p>

          <h5>Camera</h5>

          <p>
            You can use your camera to create avatars for your players. Avatars
            are stored online.
          </p>

          <h4>Information we get from your use of our services</h4>

          <p>
            We collect information about the services that you use and how you
            use them, like which pages/screens you access in the apps, which
            buttons you press when you you the apps. This information includes:
          </p>

          <h5>Device information</h5>
          <p>
            We collect device-specific information (such as your hardware model,
            operating system version and unique device identifiers). We may
            associate your device identifiers with your app Account/email.
          </p>

          <h5>Log information</h5>
          <p>
            Our services automatically record information when you visit our
            website or use our products, including the URL, IP address, and the
            date and time of your request and cookies that may uniquely identify
            your browser.
          </p>

          <h5>Location information</h5>
          <p>
            When you use our services we automatically collect information about
            your location anonymously through Google Analytics. This data is
            anonymized (according to Google) and may only show us global usage
            of our services. We also allow you to track you position while you
            play to plot throwing locations and calculate thrown distances. We
            do not collect any location data apart from the location data you
            choose to save in your games and for courses and their locations.
          </p>

          <h5>Local storage</h5>
          <p>
            We may collect and store information (including personal information
            such as your email and any information you have provided about your
            players) locally on your device using mechanisms such as browser web
            storage (including HTML 5), application data caches and local device
            databases.
          </p>

          <h5>Cookies and similar technologies</h5>
          <p>
            When you use our services, we may use one or more cookies to help
            identify your browser. We use cookies to record current session
            information. We use 3:d party software on our site which may or may
            not include the use of cookies. Google AdMob, Google AdSense,
            AddThis and Google Analytics are a few examples of the services we
            use which may require Cookies.
          </p>

          <h2>How we use information we collect</h2>

          <p>
            Any course data you provide is shared openly with all users of our
            apps unless explicitly set as "Private" course data.
          </p>

          <p>
            We reserve the right to hide and/or remove inappropriate content at
            any time if we see that the data provided is offensive or misleading
            in any way. Your account may be banned without prior notification in
            extreme situations to protect other users from harm if you do not
            behave or try and abuse our services.
          </p>

          <p>
            Your games and players are yours and are not shared with anyone else
            in our system unless you grant other access to them.
          </p>

          <p>
            We may disclose personally identifiable information under special
            circumstances, such as to comply with subpoenas, or when your
            actions violate the Terms of Service.
          </p>

          <p>
            It may be necessary to share information in order to investigate,
            prevent, or take action regarding suspected or actual illegal
            activities, including, without limitation: fraud, situations
            involving potential threats to the physical safety of any person, or
            as otherwise permitted or required by law.
          </p>

          <p>
            We may share aggregated non-personal information with third parties.
          </p>

          <p>
            If our products becomes involved in a change of ownership, we will
            provide notice before personal information is transferred and
            becomes subject to a different privacy policy.
          </p>

          <h2>Transparency and choice</h2>

          <p>
            Your email will always be stored with your data in the DiscGolf apps
            (this happens automatically from the moment you start one of our
            apps). You may decline to provide additional personal information to
            us, although some of our features or services may not function
            properly as a result.
          </p>

          <p>
            We make good faith efforts to provide you access to your personal
            information upon request and let you correct such data if it is
            inaccurate and delete it, when reasonably possible.
          </p>

          <h2>Information you share</h2>

          <p>
            We’ve taken industry-accepted measures to protect against
            unauthorized access to or unauthorized alteration, disclosure, or
            destruction of data. These include the use of encryption technology
            when collecting, transmitting, and storing sensitive data.
          </p>

          <p>
            Scorecards and Games may be shared by you at your request in our
            apps. Scorecards which are shared through 3:d party apps such as
            Facebook, email, Twitter etc will use the security they have in
            place. Games may be made publicly available through the use of a
            link/url. You are responsible for keeping this URL secure once you
            share it with anyone else.
          </p>

          <h2>Information we share</h2>

          <p>
            We do not share personal information with companies, organizations
            and individuals unless one of the following circumstances applies:
          </p>

          <h3>With administrators</h3>

          <p>
            In order for our administrators to provide you with support they may
            need to access your account information. The administrator may be
            able to:
          </p>
          <ul>
            <li>view statistics regarding your account</li>
            <li>suspend or terminate your account access</li>
            <li>access or retain information stored as part of your account</li>
            <li>
              receive your account information in order to satisfy applicable
              law, regulation, legal process or enforceable governmental request
            </li>
          </ul>

          <h3>For legal reasons</h3>

          <p>
            We will share personal information with companies, organizations or
            individuals if we have a good-faith belief that access, use,
            preservation or disclosure of the information is reasonably
            necessary to:
          </p>
          <ul>
            <li>
              meet any applicable law, regulation, legal process or enforceable
              governmental request.
            </li>
            <li>
              enforce applicable Terms of Service, including investigation of
              potential violations.
            </li>
            <li>
              detect, prevent, or otherwise address fraud, security or technical
              issues.
            </li>
            <li>
              protect against harm to the rights, property or safety of us, our
              users or the public as required or permitted by law.
            </li>
          </ul>

          <h2>Information security</h2>

          <p>
            We work hard to protect our self and our users from unauthorized
            access to or unauthorized alteration, disclosure or destruction of
            information we hold. In particular:
          </p>
          <ul>
            <li>We encrypt many of our services using SSL</li>
            <li>
              We do not handle your Google account/password nor the
              authentication mechanism. Instead we leave this to the experts at
              Google
            </li>
            <li>
              We try to keep our services up to date and implement security
              upgrades whenever possible
            </li>
          </ul>

          <h2>When this Privacy Policy applies</h2>

          <p>
            Our Privacy Policy applies to all of the services offered below:
          </p>
          <ul>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=se.codeunlimited.discgolf.lite"
                target="_blank"
                rel="noreferrer"
              >
                DiscGolf
              </a>
            </li>
          </ul>

          <p>
            <b>*</b> While these products fall under the use of this Privacy
            Policy, they themselves are only accessories to the main
            application: <b>DiscGolf</b> and as such require less permissions
            and uses considerable less personal information to work.
          </p>

          <p>
            Our Privacy Policy does not apply to services offered by other
            companies or individuals, including products or other sites linked
            from our services. Our Privacy Policy does not cover the information
            practices of other companies and organizations who advertise our
            services, and who may use cookies, pixel tags and other technologies
            to serve and offer relevant ads.
          </p>
        </div>
      </div>
    </Page>
  )
}

export default PrivacyPolicy
