import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts'

function SingleVsMultiplayer({ games }) {
  const singlePlayerCount = games.filter((g) => g.results.length === 1).length
  const data = [
    {
      value: singlePlayerCount,
      name: 'Single player rounds',
    },
    {
      value: games.length - singlePlayerCount,
      name: 'Multiplayer rounds',
      fill: '#8884d8',
    },
  ]

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={'400px'}>
      <PieChart width={400} height={400}>
        <Pie
          dataKey="value"
          isAnimationActive={true}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          label
        />
        <Legend />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default SingleVsMultiplayer
