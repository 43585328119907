import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Page from '../../component/page'
import CourseOverview from '../../component/CourseOverview'

const loadCourseGroup = (id) => ({
  type: 'LOAD_COURSE_GROUP',
  id,
  payload: {
    request: {
      url: `/v2/course/groups/${id}`,
    },
  },
})

class Course extends React.Component {
  componentDidMount() {
    this.loadGroup()
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadGroup()
    }
  }

  loadGroup = () => {
    const { groups } = this.props
    const { id } = this.props.match.params
    const group = groups.find((group) => group.id == id)

    if (!group || group.id !== id || group.courses === undefined) {
      this.props.dispatch(loadCourseGroup(id))
    }
  }

  onCourseClicked = (course) => {
    console.log('Click course', course, this.props.history)
    this.props.history.push(`/course/${course.groupId}/${course.id}`)
  }

  render() {
    const { groups } = this.props
    const { id } = this.props.match.params
    const group = groups.find((group) => group.id == id)

    if (group && group.courses !== undefined) {
      const courses = group.courses
      return (
        <Page className="container course">
          <div className="row">
            <div className="col-12">
              <h1>Course layouts in {group.name}</h1>
              {courses.map((course, index) => (
                <CourseOverview
                  key={index}
                  course={course}
                  onClicked={this.onCourseClicked}
                />
              ))}
            </div>
          </div>
        </Page>
      )
    }

    return (
      <Page className="container">
        <div className="row">
          <div className="col-12">
            <h1>Loading courses...</h1>
          </div>
        </div>
      </Page>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    course: state.course,
    groups: state.groups.data,
  }
}

export default connect(mapStateToProps)(withRouter(Course))
