import React from 'react'
import Table from 'react-bootstrap/Table'
import Distance from '../../component/Distance'

function CourseHoles({ course }) {
  return (
    <Table hover className="course-holes">
      <thead>
        <tr>
          <th>Hole</th>
          <th>Par</th>
          <th>Name</th>
          <th>Description</th>
          <th>Distance</th>
        </tr>
      </thead>
      <tbody>
        {course.holes.map(({ dist, i1, name, par }, index) => (
          <tr key={`hole-${index + 1}`}>
            <td>{index + 1}</td>
            <td>{par}</td>
            <td>{name}</td>
            <td>{i1}</td>
            <td>
              <Distance distance={dist} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default CourseHoles
