import * as R from 'ramda'
import Distance from '../Distance'
import format from 'date-fns/format'

export default function CourseOverview({ course, onClicked }) {
  const { name, info, holes } = course
  const distance = Math.round(R.sum(holes.map((hole) => hole.dist)))
  const holeCount = holes.length
  const par = R.sum(holes.map((hole) => hole.par))
  const hasMap = holes.some((hole) => hole.tee || hole.basket)

  const renderHoleNumberRow = (holes) => (
    <tr className="course-overview--hole">
      <th>Hole</th>
      {holes.map((hole, index) => (
        <td key={index}>{index + 1}</td>
      ))}
    </tr>
  )
  const renderHoleDistanceRow = (holes) =>
    holes.some((hole) => hole.dist) && (
      <tr className="course-overview--distance">
        <th>Dist</th>
        {holes.map((hole, index) => (
          <td key={index}>
            <Distance distance={hole.dist} addPostfix={false} />
          </td>
        ))}
      </tr>
    )
  const renderHoleParRow = (holes) => (
    <tr className="course-overview--par">
      <th>Par</th>
      {holes.map((hole, index) => (
        <td key={index}>{hole.par}</td>
      ))}
    </tr>
  )

  const updated = format(course.updated, 'd MMM yyyy')
  console.log('OVERVIEW', course)

  return (
    <div className="course-overview" onClick={() => onClicked(course)}>
      <h6>{name}</h6>
      <p className="course-overview--details">
        {holeCount} Holes ⸱ Par {par}
        {distance > 0 && ' ⸱ '}
        {distance > 0 && <Distance distance={distance} />}
        {hasMap && ` ⸱ Has map`}
        {' ⸱ '}
        {updated}
      </p>
      <p className="course-overview--description">{info}</p>
      <table className="course-overview--table">
        <tbody>
          {renderHoleNumberRow(holes)}
          {renderHoleDistanceRow(holes)}
          {renderHoleParRow(holes)}
        </tbody>
      </table>
    </div>
  )
}
