import React from 'react'
import { connect } from 'react-redux'
import Page from '../../component/page'
import PlayersList from './PlayersList'

class Players extends React.Component {
  render() {
    return (
      <Page className="players">
        <div className="container">
          <div className="row">
            <h1>Players</h1>
            <PlayersList players={this.props.players} />
          </div>
        </div>
      </Page>
    )
  }
}

Players.defaultProps = {
  players: [],
}

const mapStateToProps = (state) => {
  const { players } = state.user.data || {}
  return {
    players: players || [],
  }
}

export default connect(mapStateToProps)(Players)
