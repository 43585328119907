import React from 'react'
import Table from 'react-bootstrap/Table'
import format from 'date-fns/format'
import * as R from 'ramda'
import { nameById as nameCountryById } from '../../js/countries'
import { nameById as nameStateById } from '../../js/states'

function CourseDetails({ course }) {
  const renderDirectionsLink = ({ lat, lng }) => {
    // TODO Add origin if available: &origin=34.1030032,-118.41046840000001
    const destination = `${lat * 0.000001},${lng * 0.000001}`
    return (
      <a
        href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
        rel="noreferrer"
        target="_blank"
      >
        Directions
      </a>
    )
  }

  const {
    country,
    state,
    holes,
    i1: info,
    i2: info2,
    pos: location,
    name,
    updated,
  } = course

  const renderRow = (title, value) => {
    if (!value) return null

    return (
      <tr>
        <td>{title}</td>
        <td>{value}</td>
      </tr>
    )
  }

  const countryOrState = () => {
    if (country === 236) {
      return nameStateById(state)
    }
    return nameCountryById(country)
  }

  return (
    <Table hover className="course-details">
      <tbody>
        {renderRow('Name', name)}
        {renderRow('Location', countryOrState())}
        {renderRow('Description', info)}
        <tr>
          <td>Directions</td>
          <td>
            {info2}
            {info2 && location && <br />}
            {location && renderDirectionsLink(location)}
          </td>
        </tr>
        {renderRow('Par', R.sum(holes.map((hole) => hole.par)))}
        {renderRow('Holes', holes.length)}
        {renderRow('Updated', format(updated, 'yyyy-MM-dd'))}
      </tbody>
    </Table>
  )
}

export default CourseDetails
