export const filterAll = (a) => true

export const filterIsSmall = (course) => course.holes.length < 5

export const filterIsLarge = (course) => course.holes.length > 32

export const filterIsPrivate = (course) => course.priv

export const filterIsActive = (course) => course.active

export const filterIsInappropriate = (course) => course.inappropriate

export const filterHasLocation = (course) =>
  course.location != null &&
  course.location.latitude != 0 &&
  course.location.longitude != 0

export const filterWrongHoleCount = (course) =>
  (course.holeCount != course.holes.length) != 0

export const filterIsZombieCourse = (course) =>
  //course.holes.length !== 9 &&
  course.holes.every(
    (hole) =>
      hole.par === 3 &&
      hole.info === '' &&
      hole.info2 === '' &&
      !hole.distance &&
      !hole.start &&
      !hole.end
  )

export const filterHasInvalidCountryAndState = (course) =>
  !course.country || (course.country.id === 236 && !course.state)
