import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Page from '../../../component/page'
import Scorecard from './Scorecard'
import ScorecardModel from '../../../js/model/scorecard'

class GameScorecard extends React.Component {
  renderLoading = () => (
    <Page className="container">
      <div className="row">
        <h1>Scorecard Loading...</h1>
      </div>
    </Page>
  )

  render() {
    const { games, courses, players } = this.props
    const { gameId } = this.props.match.params

    const game = games.find((g) => g.id == gameId)
    if (!game) return this.renderLoading()

    const course = courses.find((c) => c.id == game.courseId)
    if (!course) return this.renderLoading()

    const scorecardPlayers = game.results.map((round) =>
      players.find((p) => p.id == round.playerId)
    )
    if (!scorecardPlayers) return this.renderLoading()

    const scorecard = new ScorecardModel(game, course, scorecardPlayers)
    console.log('Render GameScorecard', scorecard)

    return (
      <Page className="game">
        <div className="container">
          <div className="row">
            <h1>Scorecard</h1>
            <Scorecard scorecard={scorecard} />
          </div>
        </div>
      </Page>
    )
  }
}

GameScorecard.defaultProps = {
  games: [],
  courses: [],
  players: [],
}

const mapStateToProps = (state) => {
  const { games, courses, players } = state.user.data || {}
  return {
    games: games || [],
    courses: courses || [],
    players: players || [],
  }
}

export default connect(mapStateToProps)(withRouter(GameScorecard))
