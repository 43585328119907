import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import firebase from 'firebase/compat/app'
import * as firebaseui from 'firebaseui'

const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      console.log('signInSuccessWithAuthResult', authResult, redirectUrl)
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true
    },
    uiShown: function () {
      // The widget is rendered.
      // Hide the loader.
      //document.getElementById('loader').style.display = 'none';
    },
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  ],
  // Terms of service url.
  // tosUrl: '<your-tos-url>',
  // Privacy policy url.
  // privacyPolicyUrl: '<your-privacy-policy-url>'
}

class Login extends React.Component {
  hide = () => {
    this.props.dispatch({ type: 'CANCEL_LOGIN' })
  }

  componentDidMount() {
    console.log('componentDidMount Login')
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth())
    ui.start('#firebaseui-auth-container', uiConfig)
  }

  render() {
    console.log('render Login')
    return (
      <Modal show={true} onHide={this.hide} centered animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="firebaseui-auth-container" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const ConnectedLogin = connect()(Login)

const LoginWrapper = ({ show }) => (show ? <ConnectedLogin /> : null)

const mapStateToProps = (state) => {
  return {
    show: state.auth.show,
  }
}

export default connect(mapStateToProps)(LoginWrapper)
