import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'

const getPlayerThumb = async (baseUrl, player) => {
  const { imageUuid } = player
  console.log('Load user image', `${baseUrl + imageUuid}/thumb.jpeg`)
  return firebase
    .storage()
    .ref(`${baseUrl + imageUuid}/thumb.jpeg`)
    .getDownloadURL()
}

const PlayerImagesMiddleware = (store) => (next) => (action) => {
  if (action.type === 'LOAD_USER_DATA_SUCCESS') {
    const state = store.getState()
    const { uuid } = state.auth
    const { players } = action.payload.data

    if (uuid && players) {
      console.log(`LOAD_USER_DATA_SUCCESS `, uuid, players)
      const baseUrl = `user/${uuid}/player/`

      players
        .filter((player) => !!player.imageUuid)
        .map(async (player) => {
          const thumb = await getPlayerThumb(baseUrl, player)
          if (!thumb) return

          store.dispatch({
            type: 'UPDATE_PLAYER_THUMB',
            payload: {
              player,
              thumb,
            },
          })
        })
    }
  }

  return next(action)
}

export default PlayerImagesMiddleware
