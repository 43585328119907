import React from 'react'
import Chart from 'react-google-charts'
import { withRouter } from 'react-router-dom'
import { findByShort } from '../../js/countries'
import { findByName, filterByCountry } from '../../js/states'

class RegionChart extends React.Component {
  constructor(props) {
    super(props)

    const { region } = props
    const country = findByShort(region)
    const states = filterByCountry(country.id)

    this.state = {
      width: 0,
      height: 0,
      region,
      stateData: [
        ['State', 'Popularity'],
        ...states.map((state) => [state.name, 1]),
      ],
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.onResize()

    // TODO Fetch courses count per state
    // this.getStateCourseCountMap(state)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    const width = window.innerWidth
    this.setState({
      width,
      height: (width * 5) / 9,
    })
  }

  onStateSelected = ({ chartWrapper }) => {
    const chart = chartWrapper.getChart()
    const selection = chart.getSelection()
    if (selection.length === 1) {
      const [selectedItem] = selection
      const { row } = selectedItem
      const dataTable = chartWrapper.getDataTable()
      const stateName = dataTable.getValue(row, 0)
      const state = findByName(stateName)
      this.props.history.push(`/country/${this.state.region}/${state.short}`)
    }
  }

  render() {
    const { width, height, region, stateData } = this.state

    const options = {
      colors: ['#08c'],
      legend: 'none',
      tooltip: { trigger: 'none' },
      region,
      displayMode: 'regions',
      resolution: 'provinces',
    }

    if (width === 0 || height === 0) return null

    return (
      <Chart
        width={width}
        height={height}
        key={height /* triggers reload on resize window */}
        chartType="GeoChart"
        data={stateData}
        options={options}
        mapsApiKey={process.env.REACT_APP_CHARTS_KEY}
        chartEvents={[
          {
            eventName: 'select',
            callback: this.onStateSelected,
          },
        ]}
      />
    )
  }
}

export default withRouter(RegionChart)
