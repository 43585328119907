export default function DiscGolfApp(props) {
  return (
    <a
      href={process.env.REACT_APP_GOOGLE_PLAY_URL}
      rel="noreferrer"
      target="_blank"
      className="discgolfapp bi-box-arrow-up-right"
    >
      {props.children || 'DiscGolf App'}
    </a>
  )
}
