import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'
import Page from '../../component/page'
import ScorecardsList from './ScorecardsList'
import GamesMap from './GamesMap'
import SingleVsMultiplayer from './charts/SingleVsMultiplayer'
import GamesOverTime from './charts/GamesOverTime'

class Games extends React.Component {
  onCourseClicked = (course) => {
    console.log('onCourseClicked', course, this.props.history)
    this.props.history.push(`/course/${course.groupId}`)
  }

  getCenter = (coursesWithLocation) => {
    if (coursesWithLocation.length === 0) return null

    const groupedCourses = Object.values(
      R.groupBy((course) => course.groupId, coursesWithLocation)
    )
    const favCourse = R.sortBy((group) => -group.length, groupedCourses)[0][0]
    return {
      lat: favCourse.location.latitude * 0.000001,
      lng: favCourse.location.longitude * 0.000001,
    }
  }

  render() {
    const { games, courses, players } = this.props
    console.log('games', games)
    if (!games || games.length === 0) return null

    const coursesWithLocation = courses.filter((course) => course.location)
    const uniqCoursesWithLocation = R.uniqBy(
      (course) => course.groupId,
      coursesWithLocation
    )
    const center = this.getCenter(coursesWithLocation)

    return (
      <Page className="games">
        <div className="container">
          <div className="row">
            <h1>Rounds</h1>
          </div>
          <div className="row">
            {coursesWithLocation.length > 0 && (
              <GamesMap
                courses={uniqCoursesWithLocation}
                center={center}
                onCourseGroupClicked={this.onCourseClicked}
              />
            )}
          </div>
          <div className="row">
            <SingleVsMultiplayer games={games} />
            <GamesOverTime games={games} />
          </div>
          <div className="row">
            <ScorecardsList games={games} courses={courses} players={players} />
          </div>
        </div>
      </Page>
    )
  }
}

Games.defaultProps = {
  games: [],
  courses: [],
  players: [],
}

const mapStateToProps = (state) => {
  const { games, courses, players } = state.user.data || {}
  return {
    games: games || [],
    courses: courses || [],
    players: players || [],
  }
}

export default connect(mapStateToProps)(withRouter(Games))
