import format from 'date-fns/format'
import * as R from 'ramda'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

function GamesOverTime({ games }) {
  const sortedGames = R.sortBy((g) => g.ended, games)

  const yearMonthOfGame = (game) => {
    const d = new Date(game.ended)
    return d.getFullYear() * 12 + d.getMonth()
  }
  const firstGameYM = yearMonthOfGame(sortedGames[0])
  const lastGameYM = yearMonthOfGame(sortedGames[sortedGames.length - 1])
  const gamesGroupedByYearMonth = R.groupBy(yearMonthOfGame, games)

  const gamesPlayedOverTimeData = []
  for (let i = firstGameYM; i <= lastGameYM; i++) {
    const gameInYearMonth = gamesGroupedByYearMonth[i] || []
    const finishedGameInYearMonth = gameInYearMonth.filter((g) => g.locked)

    const date = new Date()
    date.setFullYear(parseInt(i / 12))
    date.setMonth(i % 12)

    gamesPlayedOverTimeData.push({
      name: format(date, 'yyyy MMM'),
      count: gameInYearMonth.length,
      locked: finishedGameInYearMonth.length,
    })
  }
  console.log('gamesGroupedByYearMonth', gamesGroupedByYearMonth)
  console.log('gamesPlayedOverTimeData', gamesPlayedOverTimeData)

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={'400px'}>
      <BarChart
        width={500}
        height={300}
        data={gamesPlayedOverTimeData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" fill="#8884d8" name="Played rounds" />
        <Bar dataKey="locked" fill="#888888" name="Finished rounds" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default GamesOverTime
