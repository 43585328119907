import React from 'react'
import Table from 'react-bootstrap/Table'
import format from 'date-fns/format'
import { withRouter } from 'react-router-dom'

function ScorecardsList(props) {
  const { games: origGames, courses, players } = props
  const games = origGames
    .map((game) => {
      const course = courses.find((c) => c.id === game.courseId)
      const results = game.results.map((result) => {
        const player = players.find((p) => p.id === result.playerId)
        return { ...result, player }
      })
      return { ...game, course, results }
    })
    .sort((g1, g2) => g2.ended - g1.ended)

  const onCourseClicked = (e, course) => {
    e.stopPropagation()
    console.log('onCourseClicked', course)
    props.history.push(`/course/${course.groupId}/${course.id}`)
  }

  const onRowClicked = (game) => {
    props.history.push(`/rounds/${game.id}`)
  }

  return (
    <Table hover className="games-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Course</th>
          <th>Players</th>
        </tr>
      </thead>
      <tbody>
        {games.map((game) => {
          return (
            <tr
              key={game.id}
              className="games-table-row"
              onClick={() => onRowClicked(game)}
            >
              <td className="games-table-col games-table-col-date">
                {format(game.ended, 'd MMM yyyy')}
              </td>
              <td
                className="games-table-col games-table-col-course"
                onClick={(e) => onCourseClicked(e, game.course)}
              >
                {game.course.name}
              </td>
              <td className="games-table-col games-table-col-players">
                {game.results.length}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

ScorecardsList.defaultProps = {
  games: [],
}

export default withRouter(ScorecardsList)
