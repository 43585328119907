import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'
import Page from '../../../component/page'
import CountryMap from '../CountryMap'
import CourseMap from '../../course/CourseMap'
import CourseTable from '../CourseTable'
import {
  filterAll,
  filterIsSmall,
  filterIsLarge,
  filterIsPrivate,
  filterIsActive,
  filterHasLocation,
  filterWrongHoleCount,
  filterIsZombieCourse,
  filterHasInvalidCountryAndState,
} from './../CourseFilters'

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const isAdmin = (user) =>
  user && user.email === 'peter.tornhult@gmail.com'

class AdminGroup extends React.Component {
  constructor(props) {
    super(props)

    const groupId = parseInt(props.match.params.groupId)
    const courses = props.courses.filter((c) => c.groupId === groupId)

    this.state = {
      groupId,
      courses,
      courseFilter: filterAll,
      filteredCourses: courses,
      selectedCourse: null,
      selectedCountry: null,
      selectedState: null,
    }
  }

  refreshData = () => {
    const { user, courses } = this.props
    const hasNoCourses = courses.length === 0
    const isNotLoadingCourses = !this.props.isLoadingCourses
    if (isAdmin(user) && hasNoCourses && isNotLoadingCourses) {
      this.props.dispatch({
        type: 'ADMIN_LOAD_ALL_COURSES',
        payload: {
          request: {
            url: `/admin/all`,
          },
        },
      })
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user || prevProps.courses.length === 0) {
      this.refreshData()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState && prevState.courses !== nextProps.courses) {
      const { groupId, courseFilter } = prevState
      const courses = nextProps.courses.filter((c) => c.groupId === groupId)
      const filteredCourses = courses.filter(courseFilter)
      console.log(
        'onCoursesChanged',
        groupId,
        nextProps.courses.length,
        ' courses:',
        courses.length,
        ' filteredCourses:',
        filteredCourses
      )
      return {
        ...prevState,
        courses,
        filteredCourses,
      }
    }
    return null
  }

  setFilter = (courseFilter) => {
    const { courses } = this.state
    const filteredCourses = courses.filter(courseFilter)
    this.setState({
      ...this.state,
      courseFilter,
      filteredCourses,
    })
  }

  percent = (tot, frac) => {
    return tot === 0 || frac === undefined
      ? 'Pending'
      : ((frac * 100) / tot).toFixed(2) + '%'
  }

  renderCourseFilterButton = (title, courseFilter) => {
    const { courses } = this.state
    const filteredCourses = courses.filter(courseFilter)
    const filteredActive = filteredCourses.filter(filterIsActive)

    console.log(title, filteredCourses.length, 'Active', filteredActive.length)
    const className =
      'btn btn-small btn-' +
      (this.state.courseFilter === courseFilter ? 'success' : 'primary')
    return (
      <div>
        <button
          onClick={() => this.setFilter(courseFilter)}
          className={className}
        >
          <b>{title}:</b> {numberWithSpaces(filteredCourses.length)}
          {'  '}({this.percent(courses.length, filteredCourses.length)}){'  '}
          {numberWithSpaces(filteredActive.length)}
          {'  '}({this.percent(courses.length, filteredActive.length)})
        </button>
      </div>
    )
  }

  renderFmtCourses = (title, filteredCourses) => {
    const { courses } = this.state
    const filteredActive = filteredCourses.filter(filterIsActive)
    console.log(title, filteredCourses.length, 'Active', filteredActive.length)
    return (
      <div>
        <b>{title}:</b> {numberWithSpaces(filteredCourses.length)}
        {'  '}({this.percent(courses.length, filteredCourses.length)}){'  '}
        {numberWithSpaces(filteredActive.length)}
        {'  '}({this.percent(courses.length, filteredActive.length)})
      </div>
    )
  }

  renderFmtValue = (title, value) => {
    console.log(title, value)
    const { courses } = this.state
    return (
      <div>
        <b>{title}:</b> {numberWithSpaces(value)}
        {'  '}({this.percent(courses.length, value)})
      </div>
    )
  }

  render() {
    if (!this.state || !isAdmin(this.props.user)) return null

    const { uniqeCoursesByName, isLoadingCourses } = this.props
    const {
      groupId,
      courses,
      filteredCourses,
      selectedCourse,
      selectedCountry,
      selectedState,
    } = this.state

    console.log('state', this.state)
    /*
        println("Groups with nearby locations: ${fmt(hasNearbyCourseGroup)}")
    */

    const onClicked = (course, column) => {
      console.log(course, column)
      if (column === 'groupId') {
        this.setState({ ...this.state, groupId: course.groupId })
      } else if (column === 'country') {
        this.setState({ ...this.state, selectedCountry: course.country })
      } else if (column === 'state') {
        this.setState({ ...this.state, selectedState: course.state })
      } else {
        this.setState({ ...this.state, selectedCourse: null })
        setTimeout(
          () => this.setState({ ...this.state, selectedCourse: course }),
          1000
        )
      }
    }

    return (
      <>
        <Page className="container admin">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <h1>
                  Admin for group: {groupId}{' '}
                  {isLoadingCourses && ' is loading...'}
                </h1>
                {this.renderCourseFilterButton('All Courses', filterAll)}
                {this.renderCourseFilterButton('Active', filterIsActive)}
                {this.renderCourseFilterButton('Private', filterIsPrivate)}
                {this.renderCourseFilterButton(
                  'Has invalid Country and State',
                  filterHasInvalidCountryAndState
                )}
                {this.renderCourseFilterButton(
                  'With Location',
                  filterHasLocation
                )}
                <span>&nbsp;</span>
                {this.renderCourseFilterButton(
                  'Wrong hole count',
                  filterWrongHoleCount
                )}
                {this.renderCourseFilterButton('Zombie', filterIsZombieCourse)}
                {this.renderCourseFilterButton('Small', filterIsSmall)}
                {this.renderCourseFilterButton('Large', filterIsLarge)}
                <span>&nbsp;</span>
                {this.renderFmtCourses('Unique by Name', uniqeCoursesByName)}
                {this.renderFmtCourses(
                  'Unique by Name and Country',
                  R.uniqBy(
                    (course) =>
                      (course.country || {}).name +
                      ':' +
                      course.name.toLowerCase(),
                    courses
                  )
                )}
                <span>&nbsp;</span>
                {this.renderFmtCourses(
                  'Listed / Filtered Curses',
                  filteredCourses
                )}
                {!!selectedCourse && (
                  <button
                    className="btn btn-small btn-secondary"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        selectedCourse: null,
                      })
                    }
                  >
                    Clear course: {selectedCourse.name}
                  </button>
                )}
                {!!selectedCountry && (
                  <button
                    className="btn btn-small btn-secondary"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        selectedCountry: null,
                      })
                    }
                  >
                    Clear country: {selectedCountry.name}
                  </button>
                )}
                {!!selectedState && (
                  <button
                    className="btn btn-small btn-secondary"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        selectedState: null,
                      })
                    }
                  >
                    Clear state: {selectedState.name}
                  </button>
                )}
                <h2>Map</h2>
                {!selectedCourse && (
                  <CountryMap
                    courses={filteredCourses}
                    country={selectedCountry}
                    state={selectedState}
                  />
                )}
                {!!selectedCourse && <CourseMap course={selectedCourse} />}
                <h2>Courses</h2>
              </div>
            </div>
          </div>
        </Page>

        <CourseTable
          courses={filteredCourses}
          uniqeCoursesByName={uniqeCoursesByName}
          groupId={groupId}
          onClicked={onClicked}
          initialSort={[{ key: 'updated', order: 'desc' }]}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { courses, uniqeCoursesByName, isLoadingCourses } = state.admin
  return {
    user: state.auth.user,
    courses,
    uniqeCoursesByName,
    isLoadingCourses,
  }
}

export default connect(mapStateToProps)(withRouter(AdminGroup))
