const sum = (arr, acc) => {
  return arr.reduce(acc, 0)
}

class Scorecard {
  constructor(game, course, players) {
    const isPlayed = (holeIndex) => game.playedHoles.charAt(holeIndex) === '1'
    const results = game.results.map((results) => {
      const extResults = JSON.parse(results.extResults || '{}').r || []
      const holeResults = course.holes.map((hole, holeIndex) => {
        const offset = holeIndex * 2
        const par = parseInt(results.results.slice(offset, offset + 2))
        const playedPar = isPlayed(holeIndex) ? par : 0
        const ext = extResults[holeIndex] || {}
        return {
          par,
          playedPar,
          hazard: ext.hz || 0,
          outOfBounds: ext.ob || 0,
          verified: ext.v || false,
        }
      })

      return {
        ...results,
        holeResults,
        playerPlayedTotal: holeResults.reduce((p, c) => p + c.playedPar, 0),
      }
    })
    const holes = course.holes

    this.game = { ...game, course, players, results, holes }
    this.printOutIn = course.holes.length % 2 === 0
    this.courseDistance =
      course.distance || Scorecard.sumHoleDistances(course.holes)
    this.coursePar = Scorecard.sumHolePar(course.holes)
    this.coursePlayedPar = sum(course.holes, (acc, hole, index) =>
      isPlayed(index) ? acc + hole.par : acc
    )
  }

  isPlayed(holeIndex) {
    return this.game.playedHoles.charAt(holeIndex) === '1'
  }

  printMiddle(holeIndex) {
    return (
      this.printOutIn && holeIndex + 1 === this.game.course.holes.length / 2
    )
  }
  printLast(holeIndex) {
    return this.printOutIn && holeIndex + 1 === this.game.course.holes.length
  }

  static sumHoleDistances(holes) {
    return sum(holes, (acc, hole) => acc + (hole.distance || 0))
  }

  static sumHolePar(holes) {
    return sum(holes, (acc, hole) => acc + hole.par)
  }
}

export default Scorecard
