import React from 'react'
import Distance from '../../../component/Distance'
import ScorecardModel from '../../../js/model/scorecard'

function Scorecard({ scorecard }) {
  console.log('Scorecard', scorecard)
  const { game } = scorecard
  const { course, players } = game
  const { holes } = course

  const printHeader = () => {
    return (
      <thead className="scorecard-header">
        <tr>
          <th className="scorecard-header-top" colSpan={2}></th>
          {holes.map((hole, holeIndex) => {
            return (
              <React.Fragment key={holeIndex}>
                <th>
                  <span className="scorecard-holeNumber">{holeIndex + 1}</span>
                </th>
                {scorecard.printMiddle(holeIndex) && (
                  <th className="scorecard-out">
                    <span className="scorecard-holeNumber">OUT</span>
                  </th>
                )}
                {scorecard.printLast(holeIndex) && (
                  <th className="scorecard-in">
                    <span className="scorecard-holeNumber">IN</span>
                  </th>
                )}
              </React.Fragment>
            )
          })}
          <th>
            <span className="scorecard-holeNumber">TOT</span>
          </th>
        </tr>
        <tr>
          <th className="scorecard-header-middle"></th>
          <th className="scorecard-header-middle">PAR</th>
          {holes.map((hole, holeIndex) => {
            return (
              <React.Fragment key={holeIndex}>
                <th>
                  <span className="scorecard-holePar">{hole.par}</span>
                </th>
                {scorecard.printMiddle(holeIndex) && (
                  <th className="scorecard-out">
                    <span className="scorecard-holePar">
                      {ScorecardModel.sumHolePar(
                        holes.slice(0, holes.length / 2)
                      )}
                    </span>
                  </th>
                )}
                {scorecard.printLast(holeIndex) && (
                  <th className="scorecard-in">
                    <span className="scorecard-holePar">
                      {ScorecardModel.sumHolePar(holes.slice(holes.length / 2))}
                    </span>
                  </th>
                )}
              </React.Fragment>
            )
          })}
          <th>
            <span className="scorecard-holePar">{course.par}</span>
          </th>
        </tr>
        <tr>
          <th className="scorecard-header-bottom scorecard-header-bottom-names">
            PLAYER
          </th>
          <th className="scorecard-header-bottom"></th>
          {holes.map((hole, holeIndex) => {
            return (
              <React.Fragment key={holeIndex}>
                <th>
                  <span className="scorecard-holeDistance">
                    <Distance distance={hole.distance} />
                  </span>
                </th>
                {scorecard.printMiddle(holeIndex) && (
                  <th className="scorecard-out">
                    <span className="scorecard-holeDistance">
                      <Distance
                        distance={ScorecardModel.sumHoleDistances(
                          holes.slice(0, holes.length / 2)
                        )}
                      />
                    </span>
                  </th>
                )}
                {scorecard.printLast(holeIndex) && (
                  <th className="scorecard-in">
                    <span className="scorecard-holeDistance">
                      <Distance
                        distance={ScorecardModel.sumHoleDistances(
                          holes.slice(holes.length / 2)
                        )}
                      />
                    </span>
                  </th>
                )}
              </React.Fragment>
            )
          })}
          <th>
            <span className="scorecard-holeDistance">
              <Distance distance={scorecard.distance} />
            </span>
          </th>
        </tr>
      </thead>
    )
  }

  const getCellClassName = (isPlayed, par, playerPar) => {
    if (!isPlayed) return 'not-played'

    const parDiff = playerPar - par
    if (parDiff <= -2) return 'eagle'
    if (parDiff === -1) return 'birdie'
    if (parDiff === 0) return 'even'
    if (parDiff === 1) return 'bogey'
    if (parDiff === 2) return 'doublebogey'
    return 'triplebogey'
  }

  const printRows = () => (
    <tbody>
      {players.map((player, index) => {
        const { holeResults, playerPlayedTotal } = game.results[index]
        const printParDiff = () => {
          const parDiff = playerPlayedTotal - scorecard.coursePlayedPar
          if (parDiff === 0) return 'E'
          return parDiff
        }

        return (
          <tr className="scorecard-row" key={`row${index}`}>
            <td className="scorecard-row-name">{player.name}</td>
            <td className="scorecard-row-par">{printParDiff()}</td>
            {holes.map((hole, holeIndex) => {
              const isPlayed = scorecard.isPlayed(holeIndex)
              const holeResult = holeResults[holeIndex]

              return (
                <React.Fragment key={holeIndex}>
                  <td
                    className={`scorecard-row-cell ${getCellClassName(
                      isPlayed,
                      hole.par,
                      holeResult.par
                    )}`}
                  >
                    {isPlayed && holeResult.par}
                  </td>
                  {scorecard.printMiddle(holeIndex) && (
                    <td className="scorecard-row-cell scorecard-out">
                      {holeResults
                        .slice(0, holes.length / 2)
                        .reduce((p, c) => p + c.playedPar, 0)}
                    </td>
                  )}
                  {scorecard.printLast(holeIndex) && (
                    <td className="scorecard-row-cell scorecard-in">
                      {holeResults
                        .slice(holes.length / 2, holes.length)
                        .reduce((p, c) => p + c.playedPar, 0)}
                    </td>
                  )}
                </React.Fragment>
              )
            })}
            <td className="scorecard-row-cell">{playerPlayedTotal}</td>
          </tr>
        )
      })}
    </tbody>
  )

  return (
    <div className="scorecard-overflow">
      <table className="scorecard">
        {printHeader()}
        {printRows()}
      </table>
    </div>
  )
}

export default Scorecard
