import React from 'react'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import format from 'date-fns/format'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as R from 'ramda'
import { isAdmin as checkIsAdmin } from '../admin'
import { nameById as nameCountryById } from '../../js/countries'
import { nameById as nameStateById } from '../../js/states'

const SORT_CONFIG = {
  initialSort: [
    { key: 'updated', order: 'desc' },
    { key: 'name', order: 'desc' },
  ],
  columns: {
    id: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: (a, b) => a - b,
    },
    name: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: (a, b) => a.localeCompare(b),
    },
    holes: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: (a, b) => a.localeCompare(b),
    },
    count: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: (a, b) => a - b,
    },
    location: {
      filter: '',
      sortOptions: ['asc', 'desc', undefined],
      sortFn: (a, b) => (!!a ? 1 : 0) - (!!b ? 1 : 0),
    },
    updated: {
      filter: '',
      sortOptions: ['asc', 'desc', undefined],
      sortFn: (a, b) => a - b,
    },
  },
}

const adminRenameGroup = (id, name, secondaryName) => ({
  type: 'ADMIN_RENAME_GROUP',
  payload: {
    request: {
      url: `/v2/course/groups/${id}/rename?name=${encodeURIComponent(
        name
      )}&secondaryName=${encodeURIComponent(secondaryName)}`,
    },
  },
})

const adminMergeGroups = (selectedIds) => ({
  type: 'ADMIN_MERGE_GROUPS',
  payload: {
    request: {
      url: `/admin/groups/merge?groupIds=${selectedIds}`,
    },
  },
})

class GroupsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sort: SORT_CONFIG.initialSort,
      admin: {
        selected: [],
        name: '',
        secondaryName: '',
      },
    }
  }

  onAdminToggleGroup = (groupId) => {
    if (!this.props.isAdmin) return

    const { selected } = this.state.admin
    let { name, secondaryName } = this.state.admin
    const index = selected.indexOf(groupId)

    if (index === -1) {
      selected.push(groupId)
      const group = this.props.groups.find((group) => group.id === groupId)
      if (selected.length === 1 && group) {
        name = group.name
        secondaryName = group.secondaryName
      }
    } else {
      selected.splice(index, 1)
    }
    this.setState({
      ...this.state,
      admin: { ...this.state.admin, selected, name, secondaryName },
    })
  }

  onAdminUpdateName = (event) => {
    console.log('onAdminUpdateName', event.target.name, event.target.value)
    this.setState({
      ...this.state,
      admin: { ...this.state.admin, name: event.target.value },
    })
  }

  onAdminUpdateSecondaryName = (event) => {
    console.log(
      'onAdminUpdateSecondaryName',
      event.target.name,
      event.target.value
    )
    this.setState({
      ...this.state,
      admin: { ...this.state.admin, secondaryName: event.target.value },
    })
  }

  onAdminRenameSelected = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { selected, name, secondaryName } = this.state.admin

    console.log('onAdminRenameSelected', selected, name, secondaryName)
    this.props.dispatch(adminRenameGroup(selected[0], name, secondaryName))
    this.setState({
      ...this.state,
      admin: { ...this.state.admin, selected: [], name: '', secondaryName: '' },
    })
  }

  onAdminMergeSelected = () => {
    if (!this.props.isAdmin) return

    this.props.dispatch(adminMergeGroups(this.state.admin.selected))
    this.setState({
      ...this.state,
      admin: { ...this.state.admin, selected: [] },
    })
  }

  renderAdminOptions = () => {
    if (!this.props.isAdmin) return null

    const { selected, name, secondaryName } = this.state.admin
    if (selected.length === 1) {
      return (
        <div className="sticky-panel-bottom">
          <Form onSubmit={this.onAdminRenameSelected}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Group name"
                defaultValue={name}
                onChange={this.onAdminUpdateName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="secondaryName">
              <Form.Label>Secondary name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Geographical area"
                defaultValue={secondaryName}
                onChange={this.onAdminUpdateSecondaryName}
              />
            </Form.Group>
            <Button variant="danger" type="submit">
              Rename group {selected[0]}
            </Button>
          </Form>
        </div>
      )
    } else if (selected.length > 1) {
      return (
        <div className="sticky-panel-bottom">
          <button
            className="btn btn-sm btn-danger"
            onClick={() => this.onAdminMergeSelected()}
          >
            Merge {selected.length} selected
          </button>
        </div>
      )
    }
  }

  toggledOrder = (columnName, order) => {
    const { sortOptions } = SORT_CONFIG.columns[columnName]
    const index = sortOptions.indexOf(order)
    return sortOptions[(index + 1) % sortOptions.length]
  }

  onSortClicked = (columnName) => {
    let { sort } = this.state

    const item = sort.find(({ key }) => key === columnName)
    if (!!item) {
      sort = sort.filter((obj) => {
        return obj.key !== columnName
      })
    }

    const order = this.toggledOrder(columnName, (item || {}).order)
    if (order !== undefined) {
      sort.push({
        key: columnName,
        order,
      })
    }

    this.setState({ ...this.state, sort })
  }

  renderSort = (columnName) => {
    const { sort } = this.state
    const columnSortConfig = sort.find(({ key }) => key === columnName)
    const { order } = columnSortConfig || {}
    const upClass = 'bi bi-caret-up' + (order === 'asc' ? '-fill' : '')
    const downClass = 'bi bi-caret-down' + (order === 'desc' ? '-fill' : '')
    const columnSortPrio = sort.indexOf(columnSortConfig)

    return (
      <div className="sort-column--order">
        <div className="sort-column--order--carets">
          <i className={upClass}></i>
          <i className={downClass}></i>
        </div>
        <div className="sort-column--order--priority">
          {columnSortPrio != -1 ? columnSortPrio + 1 : ''}
        </div>
      </div>
    )
  }

  renderDirectionsLink = ({ lat, lng }) => {
    // TODO Add origin if available: &origin=34.1030032,-118.41046840000001
    const destination = `${lat},${lng}`
    return (
      <a
        href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
        rel="noreferrer"
        target="_blank"
        className="bi-box-arrow-up-right"
      ></a>
    )
  }

  renderTableHeader = (label, column) => (
    <th
      className={`sort-column column-${column}`}
      onClick={() => this.onSortClicked(column)}
    >
      {label}
      {this.renderSort(column)}
    </th>
  )

  renderTableItem = (group, id, formatter = (s) => s) => (
    <td
      className={`column-${id}`}
      onClick={() => this.props.onCourseGroupClicked(group)}
    >
      {formatter(group[id], group)}
    </td>
  )

  render() {
    const { groups } = this.props
    if (!groups || groups.length === 0) return null

    const sort = R.reverse(this.state.sort)
    const sortedGroups = groups.sort((a, b) => {
      for (let s of sort) {
        const result = SORT_CONFIG.columns[s.key].sortFn(a[s.key], b[s.key])
        if (result !== 0) {
          if (s.order === 'desc') return result
          else return -result
        }
      }
      return 0
    })

    const showCountry = R.uniqBy((group) => group.country, groups).length > 1
    const showState = R.uniqBy((group) => group.state, groups).length > 1

    return (
      <div>
        {this.renderAdminOptions()}
        <Table hover className="courses-table">
          <thead>
            <tr>
              {this.props.isAdmin && this.renderTableHeader('ID', 'id')}
              {this.renderTableHeader('Course', 'name')}
              {showCountry && this.renderTableHeader('Country', 'country')}
              {showState && this.renderTableHeader('State', 'state')}
              {this.renderTableHeader('Holes', 'holes')}
              {this.renderTableHeader('Layouts', 'count')}
              {this.renderTableHeader('Directions', 'location')}
              {this.renderTableHeader('Updated', 'updated')}
            </tr>
          </thead>
          <tbody>
            {sortedGroups.map((group) => {
              const updated = format(group.updated, 'd MMM yy')
              return (
                <tr key={group.id} className="courses-table-row">
                  {this.props.isAdmin && (
                    <td className="column-id">
                      <Form.Check
                        type="checkbox"
                        label={group.id}
                        checked={
                          this.state.admin.selected.indexOf(group.id) !== -1
                        }
                        onChange={() => this.onAdminToggleGroup(group.id)}
                        key={`key${group.id}`}
                        id={group.id}
                      />
                    </td>
                  )}
                  {this.renderTableItem(
                    group,
                    'name',
                    (name, group) =>
                      `${group.name}${
                        group.googleRegion ? ', ' + group.googleRegion : ''
                      }`
                  )}
                  {showCountry &&
                    this.renderTableItem(group, 'country', (country) =>
                      nameCountryById(country)
                    )}
                  {showState &&
                    this.renderTableItem(group, 'state', (state) =>
                      nameStateById(state)
                    )}
                  {this.renderTableItem(group, 'holes')}
                  {this.renderTableItem(group, 'count')}
                  {group.location && (
                    <td className="column-location">
                      {this.renderDirectionsLink(group.location)}
                    </td>
                  )}
                  {!group.location && this.renderTableItem(group, 'location')}
                  {this.renderTableItem(group, 'updated', () => updated)}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}

GroupsTable.defaultProps = {
  courses: [],
}

const mapStateToProps = (state) => {
  return {
    isAdmin: checkIsAdmin(state.auth.user),
  }
}

export default connect(mapStateToProps)(withRouter(GroupsTable))
