import Page from '../../component/page'
import DiscGolfApp from '../../component/DiscGolfApp'
import QandA from './QandA'

function Faq() {
  return (
    <Page className="container faq">
      <div className="row">
        <h1>Frequently Asked Questions - Discgolf App</h1>
      </div>

      <QandA question="How do I play discgolf">
        You basically only need a disc and a course with discgolf baskets to get
        started. The sport has been around for many years now, but the rules are
        constantly evolving. To get the latest official rules of the game,
        please visit{' '}
        <a
          href="https://www.pdga.com/rules/official-rules-disc-golf"
          rel="noreferrer"
          target="_blank"
        >
          PDGA
        </a>
      </QandA>

      <QandA question="How do I add more players to a game?">
        You can add/edit/remove players when you either create or edit a game
      </QandA>

      <QandA question="I can't find the course I'm looking for?">
        <ul>
          <li>
            You can find courses close by to your location by enabling the app
            to see your location
          </li>
          <li>You can also find courses by searching for them by name</li>
          <li>
            If you still can't find the course you are looking for, chances are
            it doesn't exist in the course Database yet. The app allows you to
            create, update and add courses
          </li>
        </ul>
        <p className="alert alert-info">
          While we do have a lot of courses already, we don't have all courses
          in the world. DiscGolf maintains an free course database which we hope
          users can and will maintain by them self. Our goal with DiscGolf is
          not to maintain the course database, but to build a great app for
          discgolf players.
        </p>
      </QandA>

      <QandA question="I don't see any courses for my country?">
        <ul>
          <li>
            If you don't see any courses for your country you could try to
            refresh the courses list by swiping down from the top on the courses
            list
          </li>
          <li>
            If you still don't see any courses for your country then there is
            either a network or server error or your country doesn't have any
            courses yet. You can then add the course yourself by creating a{' '}
            <a href="#new-course">New Course</a>
          </li>
        </ul>
        <p className="alert alert-info">
          While we do have a lot of courses already, we don't have all courses
          in the world. DiscGolf maintains an free course database which we hope
          users can and will maintain by them self. Our goal with DiscGolf is
          not to maintain the course database, but to build a great app for
          discgolf players.
        </p>
      </QandA>

      <QandA question="How do I create a new course?" id="new-course">
        When you create a new game and don't find your course in the list, click
        the <i className="bi bi-plus-circle"></i> button to get started
      </QandA>

      <QandA question="The app crashes, what do I do?">
        <ol>
          <li>
            Make sure you have the latest version of the <DiscGolfApp />
          </li>
          <li>
            Submit a bug report from your phone. This way we can hopefully see
            where in the app the crash has occurred
          </li>
          <li>
            <a
              href="mailto:contact.codeunlimited+discgolf@gmail.com?subject=DiscGolf Help"
              rel="noreferrer"
              target="_blank"
            >
              Email us
            </a>{' '}
            and tell us where the app crashes and what you clicked on to get it
            to crash. Any information will help
          </li>
        </ol>
        <p className="alert alert-info">
          You need to have both <i>Google Play Services</i> and{' '}
          <i>Google Maps</i> installed on your phone for DiscGolf to work
          properly.
        </p>
      </QandA>

      <QandA question="The app doesn't work, what should I do?">
        <ul>
          <li>
            Make sure you have the latest version of the <DiscGolfApp />
          </li>
          <li>
            <a
              href="mailto:contact.codeunlimited+discgolf@gmail.com?subject=DiscGolf Help"
              rel="noreferrer"
              target="_blank"
            >
              Email us
            </a>
          </li>
        </ul>
      </QandA>

      <QandA question="What are Strokeplay, Matchplay, Bogeyplay, Stableford and Modified Stableford?">
        The DiscGolf app supports a few different Game Types / Rules. You can
        read about the different rules and how to play here:
        <ul>
          <li>
            <a href="/rules#strokeplay">Strokeplay</a>
          </li>
          <li>
            <a href="/rules#matchplay">Matchplay</a>
          </li>
          <li>
            <a href="/rules#bogeyplay">Bogeyplay</a>
          </li>
          <li>
            <a href="/rules#stableford">Stableford</a>
          </li>
          <li>
            <a href="/rules#modified_stableford">Modified Stableford</a>
          </li>
        </ul>
      </QandA>

      <QandA question="I love DiscGolf! How can I help make it better?">
        <ul>
          <li>
            <a
              href="mailto:contact.codeunlimited+discgolf@gmail.com?subject=DiscGolf Feature"
              rel="noreferrer"
              target="_blank"
            >
              Email us
            </a>{' '}
            with feature requests or suggestions. We really appreciate your
            feedback and we try to implement your requests as soon as we can
          </li>
          <li>
            Send us a good review of <DiscGolfApp>DiscGolf</DiscGolfApp> and
            tell us what you liked about the app
          </li>
        </ul>
      </QandA>

      <QandA question="How can I measure throws as accurately as possible?">
        Since the app uses GPS to measure throws, you are limited to what the
        GPS is capable of. Having a clear sky and standing in the same position
        for a while helps to increase the accuracy. If that isn't accurate
        enough you may move markers and position in the app by hand in the map
        editor mode
      </QandA>

      <QandA question="There is some inaccurate data in DiscGolf. How can I correct it?">
        All courses can be edited by anyone and changes simply creates new
        versions of the course. This is necessary so users will still have
        access to their older course / layout versions to keep accurate
        statistics
      </QandA>
    </Page>
  )
}

export default Faq
