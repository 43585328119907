import * as R from 'ramda'
import {
  filterIsActive,
  filterIsPrivate,
  filterHasLocation,
  filterIsZombieCourse,
} from './CourseFilters'

export const groupFilterSingle = (group) => group.length === 1

export const groupFilterMultiple = (group) => group.length > 1

export const groupFilterMixedPrivate = (group) => {
  if (group.length === 1) return false

  const privCount = group.filter(filterIsPrivate).length
  return privCount > 0 && privCount < group.length
}

export const groupFilterBecamePrivate = (group) => {
  if (group.length === 1) return false
  const sortedGroup = R.sort((course) => course.updated, group)

  for (var i = 0; i < sortedGroup.length - 1; i++) {
    if (!sortedGroup[i].priv && sortedGroup[i + 1].priv) return true
  }
  return false
}

export const groupFilterProblematic = (group) => {
  if (group.length === 1) return false

  const ignore = [23, 93]
  const filteredCourseCount = group.filter(
    (course) =>
      ignore.indexOf(course.id) == -1 && ignore.indexOf(course.originalId) == -1
  ).length
  if (filteredCourseCount == 0) return false

  const isProblematicChange = (c1, c2) => {
    if (c1.priv != c2.priv) return true
    if (c1.country != c2.country) return true
    if (c1.country == 236 && c1.state != c2.state) return true
    return false
  }

  for (var i = 0; i < group.length - 1; i++) {
    if (isProblematicChange(group[i], group[i + 1])) return true
  }
  return false
}

const courseHolesParToString = (course) =>
  course.holes.map((c) => '' + c.par).join()

export const groupFilterLarge = (group) =>
  R.uniq(group.map((c) => courseHolesParToString(c))).length < group.length
export const groupFilterLargeActive = (group) =>
  groupFilterLarge(group.filter(filterIsActive))

// export const groupFilterLarge = (group) => group.length >= 5
// export const groupFilterLargeActive = (group) =>
//   group.filter(filterIsActive).length >= 5

export const groupFilterWithLocation = (group) => group.some(filterHasLocation)

export const groupFilterHasSomeZombieCourses = (group) =>
  group.some(filterIsZombieCourse)

export const groupFilterHasOnlyZombieCourses = (group) =>
  group.every(filterIsZombieCourse)
