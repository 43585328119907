import React from 'react'

class QandA extends React.Component {
  constructor(props) {
    super(props)
    this.state = { show: false }
  }

  toggle() {
    this.setState({ show: !this.state.show })
  }
  render() {
    return (
      <div className="row">
        <div className="col-sm-12 faq-qna">
          <p className="faq-question" onClick={() => this.toggle()}>
            {this.props.question}
          </p>
          {this.state.show && (
            <p className="faq-answer">{this.props.children}</p>
          )}
        </div>
      </div>
    )
  }
}

export default QandA
