import React from 'react'

function Slider() {
  return (
    <div className="slider">
      <div className={'slider-background'}>
        <div className={'slider-content'}>
          <div className="slider-title">
            <span className="font-weight-lighter">Play</span>&nbsp;
            <span className="font-weight-bold">DiscGolf</span>
          </div>
          <div className="slider-text">
            Be awesome and join the fun
            <br /> Discgolf is played all over the world. Get yourself a disc
            and start playing now!
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
