import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise'
import firebase from 'firebase/compat/app'
import reducer from './reducer'
import AxiosMiddleware from './middleware/AxiosMiddleware'
import LoginMiddleware from './middleware/LoginMiddleware'
import PlayerImagesMiddleware from './middleware/PlayerImagesMiddleware'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(
      thunk,
      promiseMiddleware,
      AxiosMiddleware,
      LoginMiddleware,
      PlayerImagesMiddleware
    )
  )
)

const firebaseConfig = {
  apiKey: 'AIzaSyAh4PSl1mOAcbn5-p70UMF-SEgANand1pM',
  authDomain: 'api-project-169482247761.firebaseapp.com',
  databaseURL: 'https://api-project-169482247761.firebaseio.com',
  projectId: 'api-project-169482247761',
  storageBucket: 'api-project-169482247761.appspot.com',
  messagingSenderId: '169482247761',
  appId: '1:169482247761:web:13cf7ee64656ee985ca270',
  measurementId: 'G-CV1S5VJPEY',
}

try {
  firebase.initializeApp(firebaseConfig)
  console.log('Firebase initialized')
} catch (e) {
  // This should not happen in prod. Only a result of hot-reload
  console.warn('Firebase already initialized', e)
}

firebase.auth().onAuthStateChanged(async (user) => {
  console.log('onAuthStateChanged', user)
  const currentUser = firebase.auth().currentUser
  const idToken =
    currentUser != null ? await currentUser.getIdToken(true) : null
  console.log('IDTOKEN: ', idToken)
  store.dispatch({ type: 'AUTH_STATE_CHANGED', user, idToken })
})

export default store
