import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import Login from './component/login'
import Home from './route/home'
import Faq from './route/faq'
import Region from './route/region'
import Country from './route/country'
import Group from './route/group'
import Course from './route/course'
import Players from './route/players'
import Games from './route/games'
import Scorecard from './route/games/scorecard'
import PrintableScorecard from './route/course/PrintableScorecard'
import PrivacyPolicy from './route/privacy'
import Admin from './route/admin'
import AdminGroup from './route/admin/group'
import Page from './component/page'
import store from './store'
import ScrollToTop from './ScrollToTop'

function PageNotFound() {
  return (
    <Page>
      <h1>Page not found 404</h1>
    </Page>
  )
}

// LOAD AT INITIALIZATION
const loadCourseGroups = () => ({
  type: 'LOAD_COURSE_GROUPS',
  payload: {
    request: {
      url: `/v2/course/groups`,
    },
  },
})

class App extends Component {
  componentDidMount() {
    store.dispatch(loadCourseGroups())
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/faq">
                <Faq />
              </Route>
              <Route exact path="/region/(US|CA|AU)?">
                <Region />
              </Route>
              <Route path="/country/:country/:state?">
                <Country />
              </Route>
              <Route path="/course/:id/:courseId/print">
                <PrintableScorecard />
              </Route>
              <Route path="/course/:id/:courseId">
                <Course />
              </Route>
              <Route path="/course/:id">
                <Group />
              </Route>
              <Route path="/players">
                <Players />
              </Route>
              <Route path="/rounds/:gameId">
                <Scorecard />
              </Route>
              <Route path="/rounds">
                <Games />
              </Route>
              <Route path="/privacy*">
                <PrivacyPolicy />
              </Route>
              <Route path="/admin/:groupId">
                <AdminGroup />
              </Route>
              <Route path="/admin">
                <Admin />
              </Route>
              <Route>
                <PageNotFound />
              </Route>
            </Switch>
          </ScrollToTop>
          <Login />
        </Router>
      </Provider>
    )
  }
}

export default App //connect(mapStateToProps)(App)
