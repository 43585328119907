import React from 'react'
import Distance from '../../component/Distance'

const sumHoleDistances = (holes) =>
  holes.reduce((acc, hole) => acc + (hole.dist || 0), 0)
const sumHolePar = (holes) => holes.reduce((acc, hole) => acc + hole.par, 0)

function Scorecard({ course }) {
  const { holes } = course

  const printOutIn = holes.length % 2 === 0

  const distance = course.distance || sumHoleDistances(holes)

  const printHeader = () => {
    return (
      <thead className="scorecard-header">
        <tr>
          <th className="scorecard-header-top" colSpan={2}></th>
          {holes.map((hole, index) => {
            return (
              <React.Fragment key={index}>
                <th>
                  <span className="scorecard-holeNumber">{index + 1}</span>
                </th>
                {printOutIn && index + 1 === holes.length / 2 && (
                  <th className="scorecard-out">
                    <span className="scorecard-holeNumber">OUT</span>
                  </th>
                )}
                {printOutIn && index + 1 === holes.length && (
                  <th className="scorecard-in">
                    <span className="scorecard-holeNumber">IN</span>
                  </th>
                )}
              </React.Fragment>
            )
          })}
          <th>
            <span className="scorecard-holeNumber">TOT</span>
          </th>
        </tr>
        <tr>
          <th className="scorecard-header-middle" colSpan={2}>
            PAR
          </th>
          {holes.map((hole, index) => {
            return (
              <React.Fragment key={index}>
                <th>
                  <span className="scorecard-holePar">{hole.par}</span>
                </th>
                {printOutIn && index + 1 === holes.length / 2 && (
                  <th className="scorecard-out">
                    <span className="scorecard-holePar">
                      {sumHolePar(holes.slice(0, holes.length / 2))}
                    </span>
                  </th>
                )}
                {printOutIn && index + 1 === holes.length && (
                  <th className="scorecard-in">
                    <span className="scorecard-holePar">
                      {sumHolePar(holes.slice(holes.length / 2))}
                    </span>
                  </th>
                )}
              </React.Fragment>
            )
          })}
          <th>
            <span className="scorecard-holePar">{course.par}</span>
          </th>
        </tr>
        <tr>
          <th className="scorecard-header-bottom scorecard-header-bottom-names">
            PLAYER
          </th>
          <th className="scorecard-header-bottom"></th>
          {holes.map((hole, index) => {
            return (
              <React.Fragment key={index}>
                <th>
                  <span className="scorecard-holeDistance">
                    <Distance distance={hole.dist} />
                  </span>
                </th>
                {printOutIn && index + 1 === holes.length / 2 && (
                  <th className="scorecard-out">
                    <span className="scorecard-holeDistance">
                      <Distance
                        distance={sumHoleDistances(
                          holes.slice(0, holes.length / 2)
                        )}
                      />
                    </span>
                  </th>
                )}
                {printOutIn && index + 1 === holes.length && (
                  <th className="scorecard-in">
                    <span className="scorecard-holeDistance">
                      <Distance
                        distance={sumHoleDistances(
                          holes.slice(holes.length / 2)
                        )}
                      />
                    </span>
                  </th>
                )}
              </React.Fragment>
            )
          })}
          <th>
            <span className="scorecard-holeDistance">
              <Distance distance={distance} />
            </span>
          </th>
        </tr>
      </thead>
    )
  }
  const printRows = (rows) => {
    return (
      <tbody>
        {rows.map((row) => (
          <tr className="scorecard-row" key={`row${row}`}>
            <td className="scorecard-row-name" colSpan={2}></td>
            {holes.map((hole, index) => {
              return (
                <React.Fragment key={index}>
                  <td className="scorecard-row-cell"></td>
                  {printOutIn && index + 1 === holes.length / 2 && (
                    <td className="scorecard-row-cell scorecard-out"></td>
                  )}
                  {printOutIn && index + 1 === holes.length && (
                    <td className="scorecard-row-cell scorecard-in"></td>
                  )}
                </React.Fragment>
              )
            })}
            <td className="scorecard-row-cell"></td>
          </tr>
        ))}
      </tbody>
    )
  }

  return (
    <div className="scorecard-overflow">
      <table className="scorecard">
        {printHeader()}
        {printRows([0, 1, 2, 3, 4, 5])}
      </table>
    </div>
  )
}

export default Scorecard
