import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware'
import { v4 as uuidv4 } from 'uuid'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'X-ApiKey': process.env.REACT_APP_API_KEY,
    'X-Correlation-Id': uuidv4(),
  },
  responseType: 'text',
})

const axiosMiddlewareConfig = {
  interceptors: {
    request: [
      {
        success: function ({ getState, dispatch, getSourceAction }, req) {
          const { auth } = getState()
          const idToken = auth.idToken
          if (idToken) {
            req.headers.Authorization = `Bearer ${idToken}`
            // req.headers['Access-Control-Allow-Headers'] = 'X-Correlation-Id'
            console.log('Added auth to request hopefully', req)
          }
          return req
        },
        error: function ({ getState, dispatch, getSourceAction }, error) {
          return error
        },
      },
    ],
    response: [
      {
        success: function ({ getState, dispatch, getSourceAction }, res) {
          return Promise.resolve(res)
        },
        error: function ({ getState, dispatch, getSourceAction }, error) {
          return Promise.reject(error)
        },
      },
    ],
  },
}

export default axiosMiddleware(axiosClient, axiosMiddlewareConfig)
