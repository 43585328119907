import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Page from '../../component/page'
import DiscGolfApp from '../../component/DiscGolfApp'
import GroupsMap from './GroupsMap'
import GroupsTable from './GroupsTable'
import { findByShort as findCountryByShort } from '../../js/countries'
import { findByShort as findStateByShort } from '../../js/states'

class Country extends React.Component {
  constructor(props) {
    super(props)

    const country = findCountryByShort(props.match.params.country)
    const state = findStateByShort(props.match.params.state)

    this.state = {
      country,
      state,
    }
  }

  onCourseGroupClicked = (group) => {
    console.log('Click group', group, this.props.history)
    this.props.history.push(`/course/${group.id}`)
  }

  renderTitle = () => {
    const { country, state } = this.state
    if (state) {
      return <h1>All courses in {state.name}</h1>
    }
    return <h1>All courses in {country.name}</h1>
  }

  render() {
    const { country, state } = this.state

    const groups = this.props.groups.filter((group) => {
      if (group.country == 236) {
        return group.country === country.id && group.state === state.id
      }
      return group.country === country.id
    })

    return (
      <Page className="container country">
        <div className="row">
          <div className="col-sm-12">
            {this.renderTitle()}
            <GroupsMap
              country={country}
              state={state}
              groups={groups.filter((group) => group.location)}
              onCourseGroupClicked={this.onCourseGroupClicked}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="hidden-xs padme">
              {/* <?php include_once TEMPLATES_PATH . '/adsense.tall.html'; ?> */}
            </div>
            <h2>Courses</h2>
            <GroupsTable
              groups={groups}
              onCourseGroupClicked={this.onCourseGroupClicked}
            />
            <h2>Where do the courses come from?</h2>
            <p>
              The courses you see here have been created using the{' '}
              <DiscGolfApp />. The database is provided as a free service for
              anyone either visiting this webpage or using the <DiscGolfApp />.
              All courses are freely shared in the hope that players will
              maintain course information and share updates of their favourite
              courses for <i>everyones</i> benefit.
            </p>
            <h2>How we handle courses</h2>
            <p>
              Courses consist of one or more layouts, which may change over
              time. In 2022 we've changed the policy on how course versions are
              handled, to be able to show both courses and their different
              layouts. We have tried to merge layouts with the same name into
              courses and in a few cases even layouts with different names
              beloing to the same course. This is a manual process, so if you
              think some courses/layouts belong together you can contact us with
              names (please also include country and state) of the courses and
              we'll try and merge then as well.
            </p>

            <h2>Can't find my course or the information is incorrect</h2>
            <p>
              You can serch for the course by name at the top of this page. If
              you still can't find it then chances are that the course you are
              looking for hasn't been added into our system yet. You can create
              new courses and layouts yourself using the <DiscGolfApp />.
            </p>
          </div>
        </div>
      </Page>
    )
  }
}

const mapStateToProps = (state) => {
  const { data: groups } = state.groups
  return {
    groups,
  }
}

export default connect(mapStateToProps)(withRouter(Country))
