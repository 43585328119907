import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Page from '../../component/page'
import CourseMap from './CourseMap'
import CourseDetails from './CourseDetails'
import Scorecard from './Scorecard'
import CourseHoles from './CourseHoles'

const loadCourse = (id) => ({
  type: 'LOAD_COURSE',
  payload: {
    request: {
      url: `/v2/course/${id}`,
    },
  },
})

const loadCourseGroup = (id) => ({
  type: 'LOAD_COURSE_GROUP',
  id,
  payload: {
    request: {
      url: `/v2/course/groups/${id}`,
    },
  },
})

class Course extends React.Component {
  componentDidMount() {
    this.loadCourseData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.courseId !== prevProps.match.params.courseId) {
      this.loadCourseData()
    }
  }

  loadCourseData = () => {
    const { courseId } = this.props.match.params

    const course = this.props.course.data
    console.log('loadCourseData', courseId)
    if (course === null || course.id !== courseId) {
      this.props.dispatch(loadCourse(courseId))
      this.props.dispatch(loadCourseGroup(courseId))
    }
  }

  render() {
    const course = this.props.course.data
    if (course) {
      console.log('Course details', course)
      return (
        <Page className="container course">
          <div className="row">
            <div className="col-12">
              <h1>{course.name}</h1>
              <CourseMap course={course} />
              <CourseDetails course={course} />
              <h2>Hole details</h2>
              <CourseHoles course={course} />
              <h2>
                Scorecard
                <a
                  className="float-right"
                  href={`/course/${course.groupId}/${course.id}/print`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button variant="primary">Print</Button>
                </a>
              </h2>
              <Scorecard course={course} />
            </div>
          </div>
        </Page>
      )
    }

    return (
      <Page className="container">
        <div className="row">
          <div className="col-12">
            <h1>Loading course...</h1>
          </div>
        </div>
      </Page>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    course: state.course,
  }
}

export default connect(mapStateToProps)(withRouter(Course))
