import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'
import Page from '../../component/page'
import CountryMap from './CountryMap'
import CourseTable from './CourseTable'
import {
  filterAll,
  filterIsSmall,
  filterIsLarge,
  filterIsPrivate,
  filterIsActive,
  filterHasLocation,
  filterWrongHoleCount,
  filterIsZombieCourse,
  filterHasInvalidCountryAndState,
} from './CourseFilters'
import {
  groupFilterSingle,
  groupFilterMultiple,
  groupFilterMixedPrivate,
  groupFilterBecamePrivate,
  groupFilterProblematic,
  groupFilterLarge,
  groupFilterLargeActive,
  groupFilterWithLocation,
  groupFilterHasSomeZombieCourses,
  groupFilterHasOnlyZombieCourses,
} from './GroupFilters'

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const isAdmin = (user) =>
  user && user.email === 'peter.tornhult@gmail.com'

class Admin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      courseFilter: filterAll,
      groupFilter: filterAll,
      filteredCourses: props.courses,
    }
  }

  refreshData = () => {
    const { user, courses } = this.props
    const hasNoCourses = courses.length === 0
    const isNotLoadingCourses = !this.props.isLoadingCourses
    if (isAdmin(user) && hasNoCourses && isNotLoadingCourses) {
      this.props.dispatch({
        type: 'ADMIN_LOAD_ALL_COURSES',
        payload: {
          request: {
            url: `/admin/all`,
          },
        },
      })
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user || prevProps.courses.length === 0) {
      this.refreshData()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState && prevState.courses !== nextProps.courses) {
      console.log('onCoursesChanged', nextProps.courses.length)
      const { courseFilter, groupFilter } = prevState
      const filteredCourses = nextProps.groupedCourses
        .filter(groupFilter)
        .flatMap((group) => group)
        .filter(courseFilter)
      return {
        ...prevState,
        filteredCourses,
      }
    }
    return null
  }

  setFilter = (courseFilter, groupFilter) => {
    const filteredCourses = this.props.groupedCourses
      .filter(groupFilter)
      .flatMap((group) => group)
      .filter(courseFilter)
    this.setState({
      ...this.state,
      courseFilter,
      groupFilter,
      filteredCourses,
    })
  }

  percent = (tot, frac) => {
    return tot === 0 || frac === undefined
      ? 'Pending'
      : ((frac * 100) / tot).toFixed(2) + '%'
  }

  renderCourseFilterButton = (title, courseFilter) => {
    const { courses } = this.props
    const filteredCourses = courses.filter(courseFilter)
    const filteredActive = filteredCourses.filter(filterIsActive)

    console.log(title, filteredCourses.length, 'Active', filteredActive.length)
    const className =
      'btn btn-small btn-' +
      (this.state.courseFilter === courseFilter ? 'success' : 'primary')
    return (
      <div>
        <button
          onClick={() => this.setFilter(courseFilter, this.state.groupFilter)}
          className={className}
        >
          <b>{title}:</b> {numberWithSpaces(filteredCourses.length)}
          {'  '}({this.percent(courses.length, filteredCourses.length)}){'  '}
          {numberWithSpaces(filteredActive.length)}
          {'  '}({this.percent(courses.length, filteredActive.length)})
        </button>
      </div>
    )
  }

  renderFmtCourses = (title, filteredCourses) => {
    const { courses } = this.props
    const filteredActive = filteredCourses.filter(filterIsActive)
    console.log(title, filteredCourses.length, 'Active', filteredActive.length)
    return (
      <div>
        <b>{title}:</b> {numberWithSpaces(filteredCourses.length)}
        {'  '}({this.percent(courses.length, filteredCourses.length)}){'  '}
        {numberWithSpaces(filteredActive.length)}
        {'  '}({this.percent(courses.length, filteredActive.length)})
      </div>
    )
  }

  renderFmtValue = (title, value) => {
    const { courses } = this.props
    console.log(title, value)
    return (
      <div>
        <b>{title}:</b> {numberWithSpaces(value)}
        {'  '}({this.percent(courses.length, value)})
      </div>
    )
  }

  renderGroupFmt = (title, groupFilter) => {
    const { groupedCourses } = this.props
    const { courseFilter } = this.state
    const filteredGroups = groupedCourses.filter(groupFilter)
    console.log('filteredGroups', filteredGroups)
    const filteredGroupsWithCourseFilter = filteredGroups
      .map((c) => c.filter(courseFilter))
      .filter((g) => g.length > 0)
    const filteredGroupsWithCourseFilterAndActive = filteredGroupsWithCourseFilter
      .map((c) => c.filter(filterIsActive))
      .filter((g) => g.length > 0)

    const className =
      'btn btn-small btn-' +
      (this.state.groupFilter === groupFilter ? 'success' : 'primary')

    return (
      <div className="group-buttons">
        <button
          onClick={() => this.setFilter(this.state.courseFilter, groupFilter)}
          className={className}
        >
          <b>Group {title}:</b> {numberWithSpaces(filteredGroups.length)}
          {'  '}({this.percent(groupedCourses.length, filteredGroups.length)})
        </button>
        <button
          onClick={() => this.setFilter(this.state.courseFilter, groupFilter)}
          className={className}
        >
          <b>With coursefilter:</b>{' '}
          {numberWithSpaces(filteredGroupsWithCourseFilter.length)}
          {'  '}(
          {this.percent(
            groupedCourses.length,
            filteredGroupsWithCourseFilter.length
          )}
          )
        </button>
        <button
          onClick={() => this.setFilter(this.state.courseFilter, groupFilter)}
          className={className}
        >
          <b>Active non private:</b>{' '}
          {numberWithSpaces(filteredGroupsWithCourseFilterAndActive.length)}
          {'  '}(
          {this.percent(
            groupedCourses.length,
            filteredGroupsWithCourseFilterAndActive.length
          )}
          )
        </button>
      </div>
    )
  }

  render() {
    if (!this.state || !isAdmin(this.props.user)) return null

    const {
      courses,
      groupedCourses,
      uniqeCoursesByName,
      isLoadingCourses,
    } = this.props
    const { filteredCourses } = this.state

    console.log('state', this.state)
    /*
        println("Groups with nearby locations: ${fmt(hasNearbyCourseGroup)}")
    */

    return (
      <>
        <Page className="container admin">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <h1>Admin {isLoadingCourses && ' is loading...'}</h1>
                {this.renderCourseFilterButton('All Courses', filterAll)}
                {this.renderCourseFilterButton('Active', filterIsActive)}
                {this.renderCourseFilterButton('Private', filterIsPrivate)}
                {this.renderCourseFilterButton(
                  'Has invalid Country and State',
                  filterHasInvalidCountryAndState
                )}
                {this.renderCourseFilterButton(
                  'With Location',
                  filterHasLocation
                )}
                <span>&nbsp;</span>
                {this.renderCourseFilterButton(
                  'Wrong hole count',
                  filterWrongHoleCount
                )}
                {this.renderCourseFilterButton('Zombie', filterIsZombieCourse)}
                {this.renderCourseFilterButton('Small', filterIsSmall)}
                {this.renderCourseFilterButton('Large', filterIsLarge)}
                <span>&nbsp;</span>
                {this.renderFmtValue('Grouped', groupedCourses.length)}
                <span>&nbsp;</span>
                {this.renderGroupFmt('Single', groupFilterSingle)}
                {this.renderGroupFmt('Multiple', groupFilterMultiple)}
                {this.renderGroupFmt(
                  'Mixed With Private',
                  groupFilterMixedPrivate
                )}
                {this.renderGroupFmt(
                  'Became Private Problem',
                  groupFilterBecamePrivate
                )}
                {this.renderGroupFmt('Problems', groupFilterProblematic)}
                {this.renderGroupFmt('Large', groupFilterLarge)}
                {this.renderGroupFmt('Large active', groupFilterLargeActive)}
                {this.renderGroupFmt('Has Location', groupFilterWithLocation)}
                {this.renderGroupFmt(
                  'Has Some Zombie Curses',
                  groupFilterHasSomeZombieCourses
                )}
                {this.renderGroupFmt(
                  'Has Only Zombie Curses',
                  groupFilterHasOnlyZombieCourses
                )}
                <span>&nbsp;</span>
                {this.renderFmtCourses('Unique by Name', uniqeCoursesByName)}
                {this.renderFmtCourses(
                  'Unique by Name and Country',
                  R.uniqBy(
                    (course) =>
                      (course.country || {}).name +
                      ':' +
                      course.name.toLowerCase(),
                    courses
                  )
                )}
                <span>&nbsp;</span>
                {this.renderFmtCourses(
                  'Listed / Filtered Curses',
                  filteredCourses
                )}
                <h2>Map</h2>
                <CountryMap courses={filteredCourses} />
                <h2>Courses</h2>
              </div>
            </div>
          </div>
        </Page>
        <CourseTable
          courses={filteredCourses}
          uniqeCoursesByName={uniqeCoursesByName}
          onClicked={console.log}
          initialSort={[
            { key: 'groupId', order: 'desc' },
            { key: 'updated', order: 'desc' },
          ]}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    courses,
    groupedCourses,
    uniqeCoursesByName,
    isLoadingCourses,
  } = state.admin
  return {
    user: state.auth.user,
    courses,
    groupedCourses,
    uniqeCoursesByName,
    isLoadingCourses,
  }
}

export default connect(mapStateToProps)(withRouter(Admin))
