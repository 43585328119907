import React from 'react'
import Table from 'react-bootstrap/Table'
import format from 'date-fns/format'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'
import {
  filterIsSmall,
  filterIsLarge,
  filterIsPrivate,
  filterIsActive,
  filterIsInappropriate,
  filterHasLocation,
  filterWrongHoleCount,
  filterIsZombieCourse,
  filterHasInvalidCountryAndState,
} from './CourseFilters'

const boolSort = (a, b) => a - b
const intSort = (a, b) => a - b
const stringSort = (a, b) => a.localeCompare(b)
const undefinedSort = (a, b) => (!!a ? 1 : 0) - (!!b ? 1 : 0)
const SORT_CONFIG = {
  initialSort: [{ key: 'name', order: 'desc' }],
  columns: {
    active: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: boolSort,
    },
    priv: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: boolSort,
    },
    inappropriate: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: boolSort,
    },
    groupId: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: intSort,
    },
    id: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: intSort,
    },
    name: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: stringSort,
    },
    info: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: stringSort,
    },
    info2: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: stringSort,
    },
    country: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: (a, b) => {
        const as = !!a ? a.name : ''
        const bs = !!b ? b.name : ''
        return as.localeCompare(bs)
      },
    },
    state: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: (a, b) => {
        const as = a ? a.name : ''
        const bs = b ? b.name : ''
        return as.localeCompare(bs)
      },
    },
    holeCount: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: intSort,
    },
    par: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: intSort,
    },
    location: {
      filter: '',
      sortOptions: ['asc', 'desc', undefined],
      sortFn: undefinedSort,
    },
    updated: {
      filter: '',
      sortOptions: ['desc', 'asc', undefined],
      sortFn: intSort,
    },
  },
}

const toggleStatus = (course, verb) => ({
  type: 'ADMIN_TOGGLE_COURSE_STATUS',
  payload: {
    request: {
      url: `/admin/${course.id}/${verb}`,
    },
  },
})

const Pagination = ({ items, onClick, page = 0, pageSize = 100 }) => {
  const pages = R.splitEvery(pageSize, items)
  const render = () => (
    <div>
      {pages.map((p, index) => (
        <button
          key={index}
          onClick={() => onClick(index)}
          className={
            `btn btn-sm btn-outline-` + (index === page ? 'success' : 'danger')
          }
        >
          {index + 1}
        </button>
      ))}
    </div>
  )

  return {
    current: pages[page],
    pages,
    render,
  }
}

class CourseTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sort: this.props.initialSort,
      page: 0,
    }
  }

  renderSort = (columnName) => {
    const { sort } = this.state
    const columnSortConfig = sort.find(({ key }) => key === columnName)
    const { order } = columnSortConfig || {}
    const upClass = 'bi bi-caret-up' + (order === 'asc' ? '-fill' : '')
    const downClass = 'bi bi-caret-down' + (order === 'desc' ? '-fill' : '')
    const columnSortPrio = sort.indexOf(columnSortConfig)

    return (
      <div className="sort-column--order">
        <div className="sort-column--order--carets">
          <i className={upClass}></i>
          <i className={downClass}></i>
        </div>
        <div className="sort-column--order--priority">
          {columnSortPrio != -1 ? columnSortPrio + 1 : ''}
        </div>
      </div>
    )
  }

  toggledOrder = (columnName, order) => {
    const { sortOptions } = SORT_CONFIG.columns[columnName]
    const index = sortOptions.indexOf(order)
    return sortOptions[(index + 1) % sortOptions.length]
  }

  onSortClicked = (columnName) => {
    let { sort } = this.state

    const item = sort.find(({ key }) => key === columnName)
    if (!!item) {
      sort = sort.filter((obj) => {
        return obj.key !== columnName
      })
    }

    const order = this.toggledOrder(columnName, (item || {}).order)
    if (order !== undefined) {
      sort.push({
        key: columnName,
        order,
      })
    }

    this.setState({ ...this.state, sort })
  }

  onRowClicked = (course, column) => {
    this.props.onClicked(course, column)
  }

  onToggleStatus = (course, status) => {
    const getVerb = (course, status) => {
      if (status === 'active') {
        if (course.active) return 'inactive'
        return 'active'
      }

      if (course.priv) return 'public'
      return 'private'
    }
    const verb = getVerb(course, status)
    this.props.dispatch(toggleStatus(course, verb))
  }

  renderGroupIdLink = (groupId) => {
    return (
      <a href={`admin/${groupId}`} rel="noreferrer" target="_blank">
        {groupId}
      </a>
    )
  }

  renderDirectionsLink = ({ latitude, longitude }) => {
    // TODO Add origin if available: &origin=34.1030032,-118.41046840000001
    const destination = `${latitude * 0.000001},${longitude * 0.000001}`
    return (
      <a
        href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
        rel="noreferrer"
        target="_blank"
        className="bi-box-arrow-up-right"
      ></a>
    )
  }

  courseClasses = (course) => {
    const { uniqeCoursesByName } = this.props
    return (
      (filterIsActive(course) ? ' is-active' : ' is-inactive') +
      (filterIsPrivate(course) ? ' is-private' : ' is-public') +
      (filterIsSmall(course) ? ' is-small' : '') +
      (filterIsLarge(course) ? ' is-large' : '') +
      (filterHasLocation(course) ? ' has-location' : ' has-no-location') +
      (filterWrongHoleCount(course) ? ' has-wrong-hole-count' : '') +
      (filterIsZombieCourse(course) ? ' is-zombie' : '') +
      (filterHasInvalidCountryAndState(course) ? ' has-wrong-country' : '') +
      (uniqeCoursesByName.indexOf(course) != -1
        ? ' is-unique'
        : ' is-non-unique')
    )
  }

  courseStatus = (course) => {
    return (
      <div>
        {filterIsActive(course) ? (
          <i className="bi bi-bookmark-check-fill" />
        ) : (
          <i className="bi bi-bookmark" />
        )}
        {filterIsPrivate(course) ? (
          <i className="bi bi-eye-slash" />
        ) : (
          <i className="bi bi-eye-fill" />
        )}
        {filterIsSmall(course) && <i className="bi bi-thermometer" />}
        {filterIsLarge(course) && <i className="bi bi-thermometer-high" />}
        {filterHasLocation(course) && <i className="bi bi-pin-angle-fill" />}
        {filterWrongHoleCount(course) && <i className="bi bi-x-octagon" />}
        {filterIsZombieCourse(course) && <i className="bi bi-radioactive" />}
        {filterHasInvalidCountryAndState(course) && (
          <i className="bi bi-x-octagon" />
        )}
      </div>
    )
    // (uniqeCoursesByName.indexOf(course) != -1 ? ' unique' : ' non-unique')
  }

  render() {
    let { courses, groupId } = this.props
    if (groupId !== null) {
      courses = courses.filter((course) => course.groupId === groupId)
    }
    if (!courses || courses.length === 0) return null

    const sort = R.reverse(this.state.sort)
    const sortedCourses = courses.sort((a, b) => {
      for (let s of sort) {
        const result = SORT_CONFIG.columns[s.key].sortFn(a[s.key], b[s.key])
        if (result !== 0) {
          if (s.order === 'desc') return result
          else return -result
        }
      }
      return 0
    })
    const pagination = Pagination({
      items: sortedCourses,
      page: this.state.page,
      onClick: (page) => {
        this.setState({ ...this.state, page })
      },
    })

    const th = (name, key) => (
      <th className="sort-column" onClick={() => this.onSortClicked(key)}>
        {name} {this.renderSort(key)}
      </th>
    )

    const renderDiff = (prev, next, key) => {
      if (groupId === null || prev === null || prev[key] == next[key]) {
        if (key === 'country' || key === 'state') {
          return next[key] ? next[key].name : ''
        }
        return next[key]
      }

      if (key === 'country' || key === 'state') {
        return <span className="diff">{next[key] ? next[key].name : ''}</span>
      } else {
        return <span className="diff">{next[key]}</span>
      }
    }

    const renderHoleDiff = (prev, next, holeIndex, key) => {
      if (next.holes.length <= holeIndex) return null

      const keyMap = {
        info: 'I',
        info2: '2',
        distance: 'D',
        start: 'S',
        end: 'E',
      }

      const showDiff =
        groupId !== null && prev !== null && prev.holes.length > holeIndex

      const prevHole = showDiff ? prev.holes[holeIndex] : null
      const currentHole = next.holes[holeIndex]

      const hasInfo = (info) => !!info && info.trim().length > 0
      const hasDistance = (distance) => !!distance && distance !== '0'

      const hasLocation = (location) =>
        location != null && location.latitude != 0 && location.longitude != 0

      const isHoleKeyDiff = (prevHole, currentHole, key) => {
        switch (key) {
          case 'par':
          case 'info':
          case 'info2':
          case 'distance':
            return prevHole[key] != currentHole[key]
          case 'start':
          case 'end': {
            const l1 = prevHole[key]
            const l2 = currentHole[key]
            return (
              hasLocation(l1) !== hasLocation(l2) ||
              (hasLocation(l1) &&
                (l1.latitude != l2.latitude || l1.longitude != l2.longitude))
            )
          }
          default:
            return false
        }
      }

      const displayValue = (key, currentHole) => {
        switch (key) {
          case 'par':
            return currentHole[key]
          case 'info':
          case 'info2':
            return hasInfo(currentHole[key]) ? keyMap[key] : ''
          case 'distance':
            return hasDistance(currentHole[key]) ? keyMap[key] : ''
          case 'start':
          case 'end':
            return hasLocation(currentHole[key]) ? keyMap[key] : ''
          default:
            return ''
        }
      }

      if (showDiff && isHoleKeyDiff(prevHole, currentHole, key)) {
        return (
          <span className={'diff diff-' + key}>
            {displayValue(key, currentHole)}
          </span>
        )
      }

      return <span>{displayValue(key, currentHole)}</span>
    }

    const maxHoleCount = Math.max(
      ...pagination.current.map((c) => c.holes.length)
    )

    return (
      <>
        <Table hover className="admin courses-table table-sm">
          <thead>
            <tr>
              <th>Status</th>
              {th(
                `Active (${courses.filter(filterIsActive).length})`,
                'active'
              )}
              {th(
                `Private (${courses.filter(filterIsPrivate).length})`,
                'priv'
              )}
              {th(
                `Inappropriate (${
                  courses.filter(filterIsInappropriate).length
                })`,
                'inappropriate'
              )}
              {th(`Group ID`, 'groupId')}
              {th(`ID`, 'id')}
              {th(`Course / Layout`, 'name')}
              {th(`Description`, 'info')}
              {th(`Directions`, 'info2')}
              {th(`Country`, 'country')}
              {th(`State`, 'state')}
              {th(`Holes`, 'holeCount')}
              {th(`Par`, 'par')}
              {th(
                `Directions (${courses.filter(filterHasLocation).length})`,
                'location'
              )}
              {th(`Updated`, 'updated')}
              {[...Array(maxHoleCount)].map((_, i) => (
                <th key={'th' + i}>{i + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pagination.current.map((course, index) => {
              const prevCourse =
                index > 0 ? pagination.current[index - 1] : null
              const updated = format(course.updated, 'yyyy-MM-dd')
              return (
                <tr key={course.id} className={this.courseClasses(course)}>
                  <td>{this.courseStatus(course)}</td>
                  <td>
                    {course.active ? (
                      <button
                        className="btn btn-sm btn-outline-success"
                        onClick={() => this.onToggleStatus(course, 'active')}
                      >
                        Y
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => this.onToggleStatus(course, 'active')}
                      >
                        N
                      </button>
                    )}
                  </td>
                  <td>
                    {course.priv ? (
                      <button
                        className="btn btn-sm btn-outline-success"
                        onClick={() => this.onToggleStatus(course, 'priv')}
                      >
                        Y
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => this.onToggleStatus(course, 'priv')}
                      >
                        N
                      </button>
                    )}
                  </td>
                  <td
                    onClick={() => this.onRowClicked(course, 'inappropriate')}
                  >
                    {course.inappropriate ? 'Y' : ''}
                  </td>
                  <td>{this.renderGroupIdLink(course.groupId)}</td>
                  <td onClick={() => this.onRowClicked(course, 'id')}>
                    {course.id}
                  </td>
                  <td onClick={() => this.onRowClicked(course, 'name')}>
                    {renderDiff(prevCourse, course, 'name')}
                  </td>
                  <td
                    onClick={() => this.onRowClicked(course, 'info')}
                    className="info"
                  >
                    {renderDiff(prevCourse, course, 'info')}
                  </td>
                  <td
                    onClick={() => this.onRowClicked(course, 'info2')}
                    className="info"
                  >
                    {renderDiff(prevCourse, course, 'info2')}
                  </td>
                  <td onClick={() => this.onRowClicked(course, 'country')}>
                    {renderDiff(prevCourse, course, 'country')}
                  </td>
                  <td onClick={() => this.onRowClicked(course, 'state')}>
                    {renderDiff(prevCourse, course, 'state')}
                  </td>
                  <td onClick={() => this.onRowClicked(course, 'holeCount')}>
                    {course.holeCount + ' ' + course.holes.length}
                  </td>
                  <td onClick={() => this.onRowClicked(course, 'par')}>
                    {renderDiff(prevCourse, course, 'par')}
                  </td>
                  {course.location ? (
                    <td>{this.renderDirectionsLink(course.location)}</td>
                  ) : (
                    <td onClick={() => this.onRowClicked(course, 'location')} />
                  )}
                  <td onClick={() => this.onRowClicked(course, 'updated')}>
                    {updated}
                  </td>
                  {course.holes.map((hole, holeIndex) => (
                    <td key={'hole' + holeIndex}>
                      {renderHoleDiff(prevCourse, course, holeIndex, 'par')}
                      <br />
                      {renderHoleDiff(prevCourse, course, holeIndex, 'info')}
                      {renderHoleDiff(prevCourse, course, holeIndex, 'info2')}
                      {renderHoleDiff(
                        prevCourse,
                        course,
                        holeIndex,
                        'distance'
                      )}
                      {renderHoleDiff(prevCourse, course, holeIndex, 'start')}
                      {renderHoleDiff(prevCourse, course, holeIndex, 'end')}
                    </td>
                  ))}
                  {course.holes.length < 50 && (
                    <td colSpan={50 - course.holes.length} />
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
        {pagination.render()}
      </>
    )
  }
}

CourseTable.defaultProps = {
  courses: [],
  uniqeCoursesByName: [],
  onClicked: () => {},
  groupId: null,
  initialSort: [{ key: 'name', order: 'desc' }],
}

export default connect()(withRouter(CourseTable))
