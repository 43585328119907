import React from 'react'

function PlayerThumb(props) {
  const { player } = props
  if (!!player.thumb) {
    return <img className="player-thumb" src={player.thumb} />
  } else {
    const initials = player.name
      .split(' ')
      .map((n) => n[0].toUpperCase())
      .join('')
      .substr(0, 2)
    return <div className="player-initials">{initials}</div>
  }
}

PlayerThumb.defaultProps = {
  player: {},
}

export default PlayerThumb
