import React from 'react'
import Chart from 'react-google-charts'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { findByName, findById } from '../../js/countries'

class WorldChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    const windowWidth = window.innerWidth

    this.setState({
      width: windowWidth,
      height: (windowWidth * 5) / 9,
    })
  }

  onCountrySelected = ({ chartWrapper }) => {
    const chart = chartWrapper.getChart()
    const selection = chart.getSelection()
    if (selection.length === 1) {
      const [selectedItem] = selection
      const { row } = selectedItem
      const dataTable = chartWrapper.getDataTable()
      const countryName = dataTable.getValue(row, 0)
      const country = findByName(countryName)
      if (country.id === 236 || country.id === 14 || country.id === 40) {
        this.props.history.push(`/region/${country.short}`)
      } else {
        this.props.history.push(`/country/${country.short}`)
      }
    }
  }

  render() {
    const options = {
      colors: ['#08c'],
      legend: 'none',
      tooltip: { trigger: 'none' },
    }

    const { width, height } = this.state

    if (width === 0 || height === 0) return null

    const { countryData } = this.props
    console.log(countryData)

    return (
      <div
        className="world-chart-wrapper"
        style={{ height }}
        onMouseEnter={this.onMouseOver}
        onMouseLeave={this.onMouseOut}
      >
        <Chart
          width={width}
          height={height}
          key={height /* triggers reload on resize window */}
          chartType="GeoChart"
          data={countryData}
          options={options}
          mapsApiKey={process.env.REACT_APP_CHARTS_KEY}
          chartEvents={[
            {
              eventName: 'select',
              callback: this.onCountrySelected,
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const groups = state.groups.data
  const groupedByCountry = R.groupBy((group) => group.country, groups)
  const groupsData = Object.keys(groupedByCountry)
    .filter((countryCode) => !!countryCode && countryCode > 0)
    .map((countryCode) => {
      const courseCount = R.sum(
        groupedByCountry[countryCode].map((group) => group.count)
      )
      return [findById(countryCode).name, courseCount]
    })

  return {
    countryData: R.concat([['Country', 'Popularity']], groupsData),
  }
}

export default connect(mapStateToProps)(withRouter(WorldChart))
