function Popup({ clusters, onClickCourse }) {
  return (
    <div className="popup">
      {clusters.map((cluster) => (
        <div
          className="popup-layout"
          key={`layout-${cluster.properties.id}`}
          onClick={() => onClickCourse(cluster)}
        >
          {cluster.properties.category}
        </div>
      ))}
    </div>
  )
}

export default Popup
