function Footer() {
  return (
    <div className="footer">
      <div className="copy">
        <div className="spacer"></div>
        Copyright &copy;{' '}
        <a href="https://www.codeunlimited.se" rel="noreferrer" target="_blank">
          CodeUnlimited
        </a>
        <br></br>
        <a href="/privacy">Privacy Policy</a>
      </div>
      <div className="adbottom content text-center" />
    </div>
  )
}

export default Footer
