import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'

const Marker = ({ children }) => children

const toLatLng = (location) =>
  location
    ? {
        lat: location.lat * 0.000001,
        lng: location.lng * 0.000001,
      }
    : location

class CourseMap extends Component {
  constructor(props) {
    super(props)

    const { course } = props
    this.state = CourseMap.getStateFromCourse(course)

    this.mapRef = React.createRef()
  }

  static getStateFromCourse(course) {
    const holes = course.holes.map((hole) => {
      const { tee, basket } = hole
      return { ...hole, tee: toLatLng(tee), basket: toLatLng(basket) }
    })
    const holesWithLocation = holes.filter(
      ({ tee, basket }) => !!tee || !!basket
    )

    return {
      course,
      center: toLatLng(course.pos),
      defaultZoom: 15,
      holes,
      holesWithLocation,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState && prevState.course !== nextProps.course) {
      const { course } = nextProps
      window.location.reload() // Hack since Map won't be refreshed otherwise...(onGoogleApiLoaded only called once)
      return CourseMap.getStateFromCourse(course)
    }
    return null
  }

  renderNoLocations() {
    return (
      <div className="alert alert-danger">
        This course layout is missing hole location information
      </div>
    )
  }

  renderTee(tee, index) {
    return (
      <Marker key={`hole-tee-${index}`} lat={tee.lat} lng={tee.lng}>
        T{index + 1}
      </Marker>
    )
  }

  renderBasket(basket, index) {
    return (
      <Marker key={`hole-basket-${index}`} lat={basket.lat} lng={basket.lng}>
        B{index + 1}
      </Marker>
    )
  }

  zoomToHoleBounds(map) {
    const { holesWithLocation: holes } = this.state

    const bounds = new window.google.maps.LatLngBounds()
    holes.forEach((hole) => {
      const { tee, basket } = hole
      if (tee) {
        bounds.extend(new window.google.maps.LatLng(tee.lat, tee.lng))
      }
      if (basket) {
        bounds.extend(new window.google.maps.LatLng(basket.lat, basket.lng))
      }
    })
    map.fitBounds(bounds)
  }

  createTee(map, tee, index) {
    // new window.google.maps.Circle({
    //   strokeColor: '#08c',
    //   strokeOpacity: 1,
    //   strokeWeight: 2,
    //   fillColor: '#08c',
    //   fillOpacity: 0.85,
    //   map,
    //   center: tee,
    //   radius: 1,
    // })
    new window.google.maps.Marker({
      position: tee,
      label: {
        text: `${index + 1}`,
        color: '#fff',
      },
      map,
    })
  }

  createBasket(map, basket) {
    // Circle 1
    new window.google.maps.Circle({
      strokeColor: '#08c',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#08c',
      fillOpacity: 0.35,
      map,
      center: basket,
      radius: 10,
    })

    // Circle 2
    new window.google.maps.Circle({
      strokeColor: '#888',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#888',
      fillOpacity: 0.35,
      map,
      center: basket,
      radius: 20,
    })
  }

  createHoleLine(map, tee, basket) {
    const path = [{ ...tee }, { ...basket }]
    console.log('path', path)
    new window.google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: '#fff',
      strokeOpacity: 1.0,
      strokeWeight: 2,
      map,
    })
  }

  onGoogleApiLoaded(map) {
    this.mapRef.current = map

    this.zoomToHoleBounds(map)

    this.state.holes.forEach(({ tee, basket }, index) => {
      tee && basket && this.createHoleLine(map, tee, basket)
      tee && this.createTee(map, tee, index)
      basket && this.createBasket(map, basket)
    })
  }

  renderMap() {
    const { center, defaultZoom } = this.state
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
          defaultCenter={center}
          defaultZoom={defaultZoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map }) => this.onGoogleApiLoaded(map)}
          options={{
            mapTypeControl: true,
            mapTypeId: window.google.maps.MapTypeId.SATELLITE,
          }}
          // onChange={({ zoom, bounds }) => {
          //   this.setZoom(zoom)
          //   this.setBounds([
          //     bounds.nw.lng,
          //     bounds.se.lat,
          //     bounds.se.lng,
          //     bounds.nw.lat,
          //   ])
          // }}
        ></GoogleMapReact>
      </div>
    )
  }

  render() {
    const { holesWithLocation: holes } = this.state
    if (holes.length === 0) {
      return this.renderNoLocations()
    }

    return this.renderMap()
  }
}

export default CourseMap
