import React from 'react'
import Table from 'react-bootstrap/Table'
import PlayerThumb from './PlayerThumb'

function PlayersList(props) {
  const { players } = props
  return (
    <Table hover className="players-table">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Info</th>
          <th>Email</th>
          <th>Phone</th>
        </tr>
      </thead>
      <tbody>
        {players.map((player) => {
          return (
            <tr key={player.id} className="players-table-row">
              <td className="players-table-col players-table-col-thumb">
                <PlayerThumb player={player} />
              </td>
              <td className="players-table-col players-table-col-name">
                {player.name}
              </td>
              <td className="players-table-col players-table-col-info">
                {player.info}
              </td>
              <td className="players-table-col players-table-col-email">
                {player.email && (
                  <a href={`mailto:${player.email}`}>{player.email}</a>
                )}
              </td>
              <td className="players-table-col players-table-col-phone">
                {player.phone && (
                  <a href={`tel:${player.phone}`}>{player.phone}</a>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

PlayersList.defaultProps = {
  players: [],
}

export default PlayersList
