import React from 'react'
import Page from '../../component/page'
import DiscGolfApp from '../../component/DiscGolfApp'
import Slider from './Slider'
import WorldChart from './WorldChart'

class Home extends React.Component {
  render() {
    return (
      <Page className="home">
        <Slider />
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 feature">
              <i className="icon-frame bi-phone"></i>
              <h3>Android</h3>
              <p>
                DiscGolf is an app built for discgolf players around the world.
                If features Scorecards, Maps, Statistics and more...
              </p>
              <p>
                <DiscGolfApp>Get DiscGolf for Android</DiscGolfApp>
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature">
              <i className="icon-frame bi-watch"></i>
              <h3>Wear OS</h3>
              <p>
                DiscGolf has a built in Wear OS companion app which lets you
                keep score for multiple players directly from your wearable
                device
              </p>
              <p>
                <DiscGolfApp>Get DiscGolf for Android</DiscGolfApp>
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 feature">
              <i className="icon-frame bi-globe"></i>
              <h3>Web</h3>
              <p>
                This webpage is freely available to all discgolf players. Here
                you can view maps and print scorecards among other things
              </p>
            </div>
          </div>
        </div>
        <WorldChart />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div id="news-content">
                {/* <?php
                    for($i=0; $i<count($news) && $i<$NEWS_HISTORY_COUNT; $i++){
                        printNews($news[$i]);
                    }
                ?> */}
              </div>
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

export default Home
