import firebase from 'firebase/compat/app'
import * as R from 'ramda'
import { findById as findCountryById } from '../js/countries'
import { findById as findStateById } from '../js/states'

const initialState = {
  auth: {
    show: false,
    user: null,
    idToken: null,
    uuid: null,
  },
  user: { data: null, error: null },
  distanceFormat: 'meter',
  search: {
    results: null,
  },
  groups: { data: [], error: null, loading: false, loadingGroup: false },
  course: { data: null, error: null },
  admin: {
    courses: [],
    groupedCourses: [],
    uniqeCoursesByName: [],
    isLoadingCourses: false,
  },
}

const reducer = function (state = initialState, action) {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, auth: { ...state.auth, show: true } }

    case 'AUTH_STATE_CHANGED': {
      const { user, idToken } = action
      const isLoggedIn = user && user.emailVerified && user.email
      if (isLoggedIn) {
        return {
          ...state,
          auth: {
            ...state.auth,
            show: false,
            user,
            idToken,
          },
        }
      } else {
        return {
          ...state,
          auth: {
            show: false,
            user: null,
            idToken: null,
            uuid: null,
          },
          user: { data: null, error: null },
        }
      }
    }

    case 'LOAD_USER_DATA_SUCCESS':
      return {
        ...state,
        user: { ...state.user, data: action.payload.data, error: null },
      }

    case 'LOAD_USER_DATA_FAIL':
      return {
        ...state,
        user: { ...state.user, data: null, error: action.payload },
      }

    case 'GET_ACCOUNT_UUID_SUCCESS':
      return {
        ...state,
        auth: {
          ...state.auth,
          uuid: action.payload.data.uuid,
        },
      }

    case 'UPDATE_PLAYER_THUMB': {
      const { player, thumb } = action.payload
      const { players } = state.user.data
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            players: players.map((p) => {
              if (player.id === p.id) {
                return { ...player, thumb }
              } else {
                return p
              }
            }),
          },
        },
      }
    }

    case 'LOGOUT': {
      firebase.auth().signOut()
      return { ...state, auth: { ...state.auth, show: false, user: null } }
    }

    case 'CANCEL_LOGIN':
      return { ...state, auth: { ...state.auth, show: false } }

    case 'LOAD_COURSE_GROUPS':
      return { ...state, groups: { ...state.groups, loading: true } }

    case 'LOAD_COURSE_GROUPS_SUCCESS':
      return {
        ...state,
        groups: {
          ...state.groups,
          data: action.payload.data.map((group) => ({
            ...group,
            holes: group.holeCount.join(', '),
            location: group.location
              ? {
                  lat: group.location.lat * 0.000001,
                  lng: group.location.lng * 0.000001,
                }
              : null,
          })),
          loading: false,
          error: null,
        },
      }

    case 'LOAD_COURSE_GROUPS_FAIL':
      return {
        ...state,
        groups: { ...state.groups, loading: false, error: action.payload },
      }

    case 'LOAD_COURSE_GROUP':
      return { ...state, groups: { ...state.groups, loadingGroup: action.id } }

    case 'LOAD_COURSE_GROUP_SUCCESS':
      const groupId = action.meta.previousAction.id
      const courses = action.payload.data
      const data = state.groups.data.map((group) =>
        group.id == groupId ? { ...group, courses } : group
      )
      return {
        ...state,
        groups: {
          ...state.groups,
          data,
          loadingGroup: false,
          error: null,
        },
      }

    case 'LOAD_COURSE_GROUP_FAIL':
      return {
        ...state,
        groups: { ...state.groups, loadingGroup: false, error: action.payload },
      }

    case 'LOAD_COURSE_SUCCESS':
      return {
        ...state,
        course: { ...state.course, data: action.payload.data, error: null },
      }

    case 'LOAD_COURSE_FAIL':
      return {
        ...state,
        course: { ...state.course, data: null, error: action.payload },
      }

    case 'SEARCH_COURSES': {
      const query = action.query.toLowerCase()
      if (query.length < 2) return state

      const groups = state.groups.data
      const results = groups.filter(
        (group) => group.name.toLowerCase().indexOf(query) !== -1
      )
      console.log('SEARCH_COURSES', query, groups, results)
      return {
        ...state,
        search: {
          ...state.search,
          results,
        },
      }
    }

    case 'CLEAR_SEARCH_RESULTS':
      return {
        ...state,
        search: { ...state.search, results: null },
      }

    case 'TOGGLE_DISTANCE_FORMAT': {
      const formats = ['meter', 'feet', 'yard']
      const currentIndex = formats.indexOf(state.distanceFormat)
      const distanceFormat = formats[(currentIndex + 1) % formats.length]

      return { ...state, distanceFormat }
    }

    case 'ADMIN_LOAD_ALL_COURSES': {
      return {
        ...state,
        admin: {
          ...state.admin,
          isLoadingCourses: true,
        },
      }
    }

    case 'ADMIN_LOAD_ALL_COURSES_FAIL': {
      return {
        ...state,
        admin: {
          ...state.admin,
          isLoadingCourses: false,
        },
      }
    }

    case 'ADMIN_LOAD_ALL_COURSES_SUCCESS': {
      let courses = action.payload.data.map((course) => {
        delete course.clientId
        //delete course.groupId
        return {
          ...course,
          countryStateId:
            course.country === 236
              ? course.country * 1000 + course.state
              : course.country * 1000,
          country: findCountryById(course.country),
          state: findStateById(course.state),
        }
      })

      // Locally created groups
      // let coursesMap = courses.reduce((map, obj) => {
      //   map[obj.id] = obj
      //   return map
      // }, {})

      // const findParent = (course) => {
      //   if (course.originalId === 0) return course
      //   if (course.originalId === course.id) {
      //     return course
      //   }
      //   const parentCourse = coursesMap[course.originalId]
      //   return findParent(parentCourse)
      // }

      // function groupBy(list, keyGetter) {
      //   const map = new Map()
      //   list.forEach((item) => {
      //     const key = keyGetter(item)
      //     const collection = map.get(key)
      //     if (!collection) {
      //       map.set(key, [{ ...item, groupId: key }])
      //     } else {
      //       collection.push({ ...item, groupId: key })
      //     }
      //   })
      //   return map
      // }

      // const groupedCoursesMap = groupBy(courses, (course) => {
      //   const rootCourse = findParent(course)
      //   return rootCourse.id
      // })

      // // const groupedCourses = Array.from(groupedCoursesMap.values())
      // const groupedCourses = new Array()
      // for (let [id, courses] of groupedCoursesMap) {
      //   const filteredCourses = courses.filter((c) => c.active && !c.priv)
      //   if (filteredCourses.length > 0) {
      //     groupedCourses.push(filteredCourses)
      //   }
      // }

      // courses = groupedCourses.flatMap((group) => group.map((course) => course))
      const blahCourses = Object.values(
        R.groupBy((c) => c.name, courses)
      ).filter((group) => {
        if (group.length < 2) return false
        const countryId = parseInt(group[0].countryStateId / 1000)
        return group
          .slice(1)
          .map((g) => parseInt(g.countryStateId / 1000))
          .some((csi) => csi === countryId + 1 || csi === countryId - 1)
      })
      console.log(
        'ADMIN blahCourses',
        blahCourses,
        blahCourses.map((group) => group[0].name)
      )

      courses = courses.filter((c) => c.active && !c.priv && c.groupId !== 0)
      const groupedCourses = Object.values(
        R.groupBy((course) => course.groupId, courses)
      )

      const uniqCourseIdentifier = (course) =>
        course.name.toLowerCase().trim() + course.countryStateId

      console.log('ADMIN courses', courses)
      console.log('ADMIN groupedCourses', groupedCourses)

      const problemGroups = groupedCourses.filter((group) => {
        if (group.length < 2) return false
        const firstGroupName = group[0].name
          .replaceAll(/[,.]/gi, ' ')
          .replaceAll(/\s\s/g, ' ')
          .split(' ')[0]
          .toLowerCase()
        return group
          .slice(1)
          .map((g) =>
            g.name
              .replaceAll(/[,.]/gi, ' ')
              .replaceAll(/\s\s/g, ' ')
              .split(' ')[0]
              .toLowerCase()
          )
          .some((name) => name != firstGroupName)
      })
      console.log(
        'ADMIN problemGroups',
        problemGroups,
        problemGroups.map((group) => group.map((g) => g.name))
      )

      return {
        ...state,
        admin: {
          ...state.admin,
          isLoadingCourses: false,
          courses,
          groupedCourses,
          uniqeCoursesByName: R.uniqBy(
            uniqCourseIdentifier,
            R.reverse(R.filter((course) => course.active, courses))
          ),
        },
      }
    }

    case 'ADMIN_TOGGLE_COURSE_STATUS_SUCCESS': {
      const { id, active, priv, updated } = action.payload.data
      console.log('ADMIN_TOGGLE_COURSE_STATUS_SUCCESS', action.payload.data)

      const courses = state.admin.courses.map((course) =>
        course.id === id
          ? {
              ...course,
              active,
              priv,
              updated,
            }
          : course
      )

      const uniqCourseIdentifier = (course) =>
        course.name.toLowerCase().trim() + course.countryStateId

      return {
        ...state,
        admin: {
          ...state.admin,
          courses,
          uniqeCoursesByName: R.uniqBy(
            uniqCourseIdentifier,
            R.reverse(R.filter((course) => course.active, courses))
          ),
        },
      }
    }

    case 'ADMIN_RENAME_GROUP_SUCCESS': {
      console.log('Course group has been renamed, reload groups now')
      return state
    }

    case 'ADMIN_MERGE_GROUPS_SUCCESS': {
      console.log('Courses have been merged, reload groups now')
      return state
    }

    default:
      return state
  }
}

export default reducer
