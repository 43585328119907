const states = {
  1: { id: 1, country: 236, short: 'AL', name: 'Alabama' },
  2: { id: 2, country: 236, short: 'AK', name: 'Alaska' },
  3: { id: 3, country: 236, short: 'AZ', name: 'Arizona' },
  4: { id: 4, country: 236, short: 'AR', name: 'Arkansas' },
  5: { id: 5, country: 236, short: 'CA', name: 'California' },
  6: { id: 6, country: 236, short: 'CO', name: 'Colorado' },
  7: { id: 7, country: 236, short: 'CT', name: 'Connecticut' },
  8: { id: 8, country: 236, short: 'DE', name: 'Delaware' },
  9: { id: 9, country: 236, short: 'DC', name: 'District of Columbia' },
  10: { id: 10, country: 236, short: 'FL', name: 'Florida' },
  11: { id: 11, country: 236, short: 'GA', name: 'Georgia' },
  12: { id: 12, country: 236, short: 'HI', name: 'Hawaii' },
  13: { id: 13, country: 236, short: 'ID', name: 'Idaho' },
  14: { id: 14, country: 236, short: 'IL', name: 'Illinois' },
  15: { id: 15, country: 236, short: 'IN', name: 'Indiana' },
  16: { id: 16, country: 236, short: 'IA', name: 'Iowa' },
  17: { id: 17, country: 236, short: 'KS', name: 'Kansas' },
  18: { id: 18, country: 236, short: 'KY', name: 'Kentucky' },
  19: { id: 19, country: 236, short: 'LA', name: 'Louisiana' },
  20: { id: 20, country: 236, short: 'ME', name: 'Maine' },
  21: { id: 21, country: 236, short: 'MD', name: 'Maryland' },
  22: { id: 22, country: 236, short: 'MA', name: 'Massachusetts' },
  23: { id: 23, country: 236, short: 'MI', name: 'Michigan' },
  24: { id: 24, country: 236, short: 'MN', name: 'Minnesota' },
  25: { id: 25, country: 236, short: 'MS', name: 'Mississippi' },
  26: { id: 26, country: 236, short: 'MO', name: 'Missouri' },
  27: { id: 27, country: 236, short: 'MT', name: 'Montana' },
  28: { id: 28, country: 236, short: 'NE', name: 'Nebraska' },
  29: { id: 29, country: 236, short: 'NV', name: 'Nevada' },
  30: { id: 30, country: 236, short: 'NH', name: 'New Hampshire' },
  31: { id: 31, country: 236, short: 'NJ', name: 'New Jersey' },
  32: { id: 32, country: 236, short: 'NM', name: 'New Mexico' },
  33: { id: 33, country: 236, short: 'NY', name: 'New York' },
  34: { id: 34, country: 236, short: 'NC', name: 'North Carolina' },
  35: { id: 35, country: 236, short: 'ND', name: 'North Dakota' },
  36: { id: 36, country: 236, short: 'OH', name: 'Ohio' },
  37: { id: 37, country: 236, short: 'OK', name: 'Oklahoma' },
  38: { id: 38, country: 236, short: 'OR', name: 'Oregon' },
  39: { id: 39, country: 236, short: 'PA', name: 'Pennsylvania' },
  40: { id: 40, country: 236, short: 'RI', name: 'Rhode Island' },
  41: { id: 41, country: 236, short: 'SC', name: 'South Carolina' },
  42: { id: 42, country: 236, short: 'SD', name: 'South Dakota' },
  43: { id: 43, country: 236, short: 'TN', name: 'Tennessee' },
  44: { id: 44, country: 236, short: 'TX', name: 'Texas' },
  45: { id: 45, country: 236, short: 'UT', name: 'Utah' },
  46: { id: 46, country: 236, short: 'VT', name: 'Vermont' },
  47: { id: 47, country: 236, short: 'VA', name: 'Virginia' },
  48: { id: 48, country: 236, short: 'WA', name: 'Washington' },
  49: { id: 49, country: 236, short: 'WV', name: 'West Virginia' },
  50: { id: 50, country: 236, short: 'WI', name: 'Wisconsin' },
  51: { id: 51, country: 236, short: 'WY', name: 'Wyoming' },
  52: { id: 52, country: 236, short: 'AS', name: 'American Samoa' },
  53: { id: 53, country: 236, short: 'GU', name: 'Guam' },
  54: { id: 54, country: 236, short: 'MP', name: 'Northern Mariana Islands' },
  55: { id: 55, country: 236, short: 'PR', name: 'Puerto Rico' },
  56: { id: 56, country: 236, short: 'VI', name: 'Virgin Islands' },
  57: {
    id: 57,
    country: 236,
    short: 'FM',
    name: 'Federated States of Micronesia',
  },
  58: { id: 58, country: 236, short: 'MH', name: 'Marshall Islands' },
  59: { id: 59, country: 236, short: 'PW', name: 'Palau' },
  60: { id: 60, country: 236, short: 'AA', name: 'Armed Forces Americas' },
  61: { id: 61, country: 236, short: 'AE', name: 'Armed Forces' },
  62: { id: 62, country: 236, short: 'AP', name: 'Armed Forces Pacific' },
  63: { id: 63, country: 236, short: 'CZ', name: 'Canal Zone' },
  64: { id: 64, country: 236, short: 'PI', name: 'Philippine Islands' },
  65: {
    id: 65,
    country: 236,
    short: 'TT',
    name: 'Trust Territory of the Pacific Islands',
  },
  66: {
    id: 66,
    country: 236,
    short: 'CM',
    name: 'Commonwealth of the Northern Mariana Islands',
  },
  67: {
    id: 67,
    country: 14,
    short: 'CT',
    name: 'Australian Capital Territory',
  },
  68: { id: 68, country: 14, short: 'NS', name: 'New South Wales' },
  69: { id: 69, country: 14, short: 'NT', name: 'Northern Territory' },
  70: { id: 70, country: 14, short: 'QL', name: 'Queensland' },
  71: { id: 71, country: 14, short: 'SA', name: 'South Australia' },
  72: { id: 72, country: 14, short: 'TS', name: 'Tasmania' },
  73: { id: 73, country: 14, short: 'VI', name: 'Victoria' },
  74: { id: 74, country: 14, short: 'WA', name: 'Western Australia' },
  75: { id: 75, country: 40, short: 'AB', name: 'Alberta' },
  76: { id: 76, country: 40, short: 'BC', name: 'British Columbia' },
  77: { id: 77, country: 40, short: 'MB', name: 'Manitoba' },
  78: { id: 78, country: 40, short: 'NB', name: 'New Brunswick' },
  79: { id: 79, country: 40, short: 'NF', name: 'Newfoundland' },
  80: { id: 80, country: 40, short: 'NT', name: 'Northwest Territories' },
  81: { id: 81, country: 40, short: 'NS', name: 'Nova Scotia' },
  82: { id: 82, country: 40, short: 'NU', name: 'Nunavut' },
  83: { id: 83, country: 40, short: 'ON', name: 'Ontario' },
  84: { id: 84, country: 40, short: 'PE', name: 'Prince Edward Island' },
  85: { id: 85, country: 40, short: 'QC', name: 'Quebec' },
  86: { id: 86, country: 40, short: 'SK', name: 'Saskatchewan' },
  87: { id: 87, country: 40, short: 'YT', name: 'Yukon Territory' },
}

const findByName = (name) =>
  Object.values(states).find((state) => state.name === name)

const findByShort = (short) =>
  Object.values(states).find((state) => state.short === short)

const filterByCountry = (country) =>
  Object.values(states).filter((state) => state.country === country)

const findById = (id) => states[id]

const nameById = (id) => {
  const state = findById(id)
  return !!state ? state.name : ''
}

export {
  states as default,
  findByName,
  findByShort,
  findById,
  filterByCountry,
  nameById,
}
