const countries = {
  1: { id: 1, name: 'Afghanistan', short: 'AF' },
  2: { id: 2, name: 'Åland Islands', short: 'AX' },
  3: { id: 3, name: 'Albania', short: 'AL' },
  4: { id: 4, name: 'Algeria', short: 'DZ' },
  5: { id: 5, name: 'American Samoa', short: 'AS' },
  6: { id: 6, name: 'Andorra', short: 'AD' },
  7: { id: 7, name: 'Angola', short: 'AO' },
  8: { id: 8, name: 'Anguilla', short: 'AI' },
  9: { id: 9, name: 'Antarctica', short: 'AQ' },
  10: { id: 10, name: 'Antigua and Barbuda', short: 'AG' },
  11: { id: 11, name: 'Argentina', short: 'AR' },
  12: { id: 12, name: 'Armenia', short: 'AM' },
  13: { id: 13, name: 'Aruba', short: 'AW' },
  14: { id: 14, name: 'Australia', short: 'AU' },
  15: { id: 15, name: 'Austria', short: 'AT' },
  16: { id: 16, name: 'Azerbaijan', short: 'AZ' },
  17: { id: 17, name: 'Bahamas', short: 'BS' },
  18: { id: 18, name: 'Bahrain', short: 'BH' },
  19: { id: 19, name: 'Bangladesh', short: 'BD' },
  20: { id: 20, name: 'Barbados', short: 'BB' },
  21: { id: 21, name: 'Belarus', short: 'BY' },
  22: { id: 22, name: 'Belgium', short: 'BE' },
  23: { id: 23, name: 'Belize', short: 'BZ' },
  24: { id: 24, name: 'Benin', short: 'BJ' },
  25: { id: 25, name: 'Bermuda', short: 'BM' },
  26: { id: 26, name: 'Bhutan', short: 'BT' },
  27: { id: 27, name: 'Bolivia', short: 'BO' },
  28: { id: 28, name: 'Bonaire', short: 'BQ' },
  29: { id: 29, name: 'Bosnia and Herzegovina', short: 'BA' },
  30: { id: 30, name: 'Botswana', short: 'BW' },
  31: { id: 31, name: 'Bouvet Island', short: 'BV' },
  32: { id: 32, name: 'Brazil', short: 'BR' },
  33: { id: 33, name: 'British Indian Ocean Territory', short: 'IO' },
  34: { id: 34, name: 'Brunei Darussalam', short: 'BN' },
  35: { id: 35, name: 'Bulgaria', short: 'BG' },
  36: { id: 36, name: 'Burkina Faso', short: 'BF' },
  37: { id: 37, name: 'Burundi', short: 'BI' },
  38: { id: 38, name: 'Cambodia', short: 'KH' },
  39: { id: 39, name: 'Cameroon', short: 'CM' },
  40: { id: 40, name: 'Canada', short: 'CA' },
  41: { id: 41, name: 'Cape Verde', short: 'CV' },
  42: { id: 42, name: 'Cayman Islands', short: 'KY' },
  43: { id: 43, name: 'Central African Republic', short: 'CF' },
  44: { id: 44, name: 'Chad', short: 'TD' },
  45: { id: 45, name: 'Chile', short: 'CL' },
  46: { id: 46, name: 'China', short: 'CN' },
  47: { id: 47, name: 'Christmas Island', short: 'CX' },
  48: { id: 48, name: 'Cocos (Keeling) Islands', short: 'CC' },
  49: { id: 49, name: 'Colombia', short: 'CO' },
  50: { id: 50, name: 'Comoros', short: 'KM' },
  51: { id: 51, name: 'Congo', short: 'CG' },
  52: { id: 52, name: 'Congo', short: 'CD' },
  53: { id: 53, name: 'Cook Islands', short: 'CK' },
  54: { id: 54, name: 'Costa Rica', short: 'CR' },
  55: { id: 55, name: "Côte d'Ivoire", short: 'CI' },
  56: { id: 56, name: 'Croatia', short: 'HR' },
  57: { id: 57, name: 'Cuba', short: 'CU' },
  58: { id: 58, name: 'Curaçao', short: 'CW' },
  59: { id: 59, name: 'Cyprus', short: 'CY' },
  60: { id: 60, name: 'Czech Republic', short: 'CZ' },
  61: { id: 61, name: 'Denmark', short: 'DK' },
  62: { id: 62, name: 'Djibouti', short: 'DJ' },
  63: { id: 63, name: 'Dominica', short: 'DM' },
  64: { id: 64, name: 'Dominican Republic', short: 'DO' },
  65: { id: 65, name: 'Ecuador', short: 'EC' },
  66: { id: 66, name: 'Egypt', short: 'EG' },
  67: { id: 67, name: 'El Salvador', short: 'SV' },
  68: { id: 68, name: 'Equatorial Guinea', short: 'GQ' },
  69: { id: 69, name: 'Eritrea', short: 'ER' },
  70: { id: 70, name: 'Estonia', short: 'EE' },
  71: { id: 71, name: 'Ethiopia', short: 'ET' },
  72: { id: 72, name: 'Falkland Islands (Malvinas)', short: 'FK' },
  73: { id: 73, name: 'Faroe Islands', short: 'FO' },
  74: { id: 74, name: 'Fiji', short: 'FJ' },
  75: { id: 75, name: 'Finland', short: 'FI' },
  76: { id: 76, name: 'France', short: 'FR' },
  77: { id: 77, name: 'French Guiana', short: 'GF' },
  78: { id: 78, name: 'French Polynesia', short: 'PF' },
  79: { id: 79, name: 'French Southern Territories', short: 'TF' },
  80: { id: 80, name: 'Gabon', short: 'GA' },
  81: { id: 81, name: 'Gambia', short: 'GM' },
  82: { id: 82, name: 'Georgia', short: 'GE' },
  83: { id: 83, name: 'Germany', short: 'DE' },
  84: { id: 84, name: 'Ghana', short: 'GH' },
  85: { id: 85, name: 'Gibraltar', short: 'GI' },
  86: { id: 86, name: 'Greece', short: 'GR' },
  87: { id: 87, name: 'Greenland', short: 'GL' },
  88: { id: 88, name: 'Grenada', short: 'GD' },
  89: { id: 89, name: 'Guadeloupe', short: 'GP' },
  90: { id: 90, name: 'Guam', short: 'GU' },
  91: { id: 91, name: 'Guatemala', short: 'GT' },
  92: { id: 92, name: 'Guernsey', short: 'GG' },
  93: { id: 93, name: 'Guinea', short: 'GN' },
  94: { id: 94, name: 'Guinea-Bissau', short: 'GW' },
  95: { id: 95, name: 'Guyana', short: 'GY' },
  96: { id: 96, name: 'Haiti', short: 'HT' },
  97: { id: 97, name: 'Heard Island and McDonald Islands', short: 'HM' },
  98: { id: 98, name: 'Holy See (Vatican City State)', short: 'VA' },
  99: { id: 99, name: 'Honduras', short: 'HN' },
  100: { id: 100, name: 'Hong Kong', short: 'HK' },
  101: { id: 101, name: 'Hungary', short: 'HU' },
  102: { id: 102, name: 'Iceland', short: 'IS' },
  103: { id: 103, name: 'India', short: 'IN' },
  104: { id: 104, name: 'Indonesia', short: 'ID' },
  105: { id: 105, name: 'Iran', short: 'IR' },
  106: { id: 106, name: 'Iraq', short: 'IQ' },
  107: { id: 107, name: 'Ireland', short: 'IE' },
  108: { id: 108, name: 'Isle of Man', short: 'IM' },
  109: { id: 109, name: 'Israel', short: 'IL' },
  110: { id: 110, name: 'Italy', short: 'IT' },
  111: { id: 111, name: 'Jamaica', short: 'JM' },
  112: { id: 112, name: 'Japan', short: 'JP' },
  113: { id: 113, name: 'Jersey', short: 'JE' },
  114: { id: 114, name: 'Jordan', short: 'JO' },
  115: { id: 115, name: 'Kazakhstan', short: 'KZ' },
  116: { id: 116, name: 'Kenya', short: 'KE' },
  117: { id: 117, name: 'Kiribati', short: 'KI' },
  118: { id: 118, name: 'Korea', short: 'KP' },
  119: { id: 119, name: 'Korea', short: 'KR' },
  120: { id: 120, name: 'Kuwait', short: 'KW' },
  121: { id: 121, name: 'Kyrgyzstan', short: 'KG' },
  122: { id: 122, name: 'Lao', short: 'LA' },
  123: { id: 123, name: 'Latvia', short: 'LV' },
  124: { id: 124, name: 'Lebanon', short: 'LB' },
  125: { id: 125, name: 'Lesotho', short: 'LS' },
  126: { id: 126, name: 'Liberia', short: 'LR' },
  127: { id: 127, name: 'Libyan Arab Jamahiriya', short: 'LY' },
  128: { id: 128, name: 'Liechtenstein', short: 'LI' },
  129: { id: 129, name: 'Lithuania', short: 'LT' },
  130: { id: 130, name: 'Luxembourg', short: 'LU' },
  131: { id: 131, name: 'Macao', short: 'MO' },
  132: { id: 132, name: 'Macedonia', short: 'MK' },
  133: { id: 133, name: 'Madagascar', short: 'MG' },
  134: { id: 134, name: 'Malawi', short: 'MW' },
  135: { id: 135, name: 'Malaysia', short: 'MY' },
  136: { id: 136, name: 'Maldives', short: 'MV' },
  137: { id: 137, name: 'Mali', short: 'ML' },
  138: { id: 138, name: 'Malta', short: 'MT' },
  139: { id: 139, name: 'Marshall Islands', short: 'MH' },
  140: { id: 140, name: 'Martinique', short: 'MQ' },
  141: { id: 141, name: 'Mauritania', short: 'MR' },
  142: { id: 142, name: 'Mauritius', short: 'MU' },
  143: { id: 143, name: 'Mayotte', short: 'YT' },
  144: { id: 144, name: 'Mexico', short: 'MX' },
  145: { id: 145, name: 'Micronesia', short: 'FM' },
  146: { id: 146, name: 'Moldova', short: 'MD' },
  147: { id: 147, name: 'Monaco', short: 'MC' },
  148: { id: 148, name: 'Mongolia', short: 'MN' },
  149: { id: 149, name: 'Montenegro', short: 'ME' },
  150: { id: 150, name: 'Montserrat', short: 'MS' },
  151: { id: 151, name: 'Morocco', short: 'MA' },
  152: { id: 152, name: 'Mozambique', short: 'MZ' },
  153: { id: 153, name: 'Myanmar', short: 'MM' },
  154: { id: 154, name: 'Namibia', short: 'NA' },
  155: { id: 155, name: 'Nauru', short: 'NR' },
  156: { id: 156, name: 'Nepal', short: 'NP' },
  157: { id: 157, name: 'Netherlands', short: 'NL' },
  158: { id: 158, name: 'New Caledonia', short: 'NC' },
  159: { id: 159, name: 'New Zealand', short: 'NZ' },
  160: { id: 160, name: 'Nicaragua', short: 'NI' },
  161: { id: 161, name: 'Niger', short: 'NE' },
  162: { id: 162, name: 'Nigeria', short: 'NG' },
  163: { id: 163, name: 'Niue', short: 'NU' },
  164: { id: 164, name: 'Norfolk Island', short: 'NF' },
  165: { id: 165, name: 'Northern Mariana Islands', short: 'MP' },
  166: { id: 166, name: 'Norway', short: 'NO' },
  167: { id: 167, name: 'Oman', short: 'OM' },
  168: { id: 168, name: 'Pakistan', short: 'PK' },
  169: { id: 169, name: 'Palau', short: 'PW' },
  170: { id: 170, name: 'Palestinian Territory', short: 'PS' },
  171: { id: 171, name: 'Panama', short: 'PA' },
  172: { id: 172, name: 'Papua New Guinea', short: 'PG' },
  173: { id: 173, name: 'Paraguay', short: 'PY' },
  174: { id: 174, name: 'Peru', short: 'PE' },
  175: { id: 175, name: 'Philippines', short: 'PH' },
  176: { id: 176, name: 'Pitcairn', short: 'PN' },
  177: { id: 177, name: 'Poland', short: 'PL' },
  178: { id: 178, name: 'Portugal', short: 'PT' },
  179: { id: 179, name: 'Puerto Rico', short: 'PR' },
  180: { id: 180, name: 'Qatar', short: 'QA' },
  181: { id: 181, name: 'Réunion', short: 'RE' },
  182: { id: 182, name: 'Romania', short: 'RO' },
  183: { id: 183, name: 'Russian Federation', short: 'RU' },
  184: { id: 184, name: 'Rwanda', short: 'RW' },
  185: { id: 185, name: 'Saint Barthélemy', short: 'BL' },
  186: { id: 186, name: 'Saint Helena', short: 'SH' },
  187: { id: 187, name: 'Saint Kitts and Nevis', short: 'KN' },
  188: { id: 188, name: 'Saint Lucia', short: 'LC' },
  189: { id: 189, name: 'Saint Martin (French part)', short: 'MF' },
  190: { id: 190, name: 'Saint Pierre and Miquelon', short: 'PM' },
  191: { id: 191, name: 'Saint Vincent and the Grenadines', short: 'VC' },
  192: { id: 192, name: 'Samoa', short: 'WS' },
  193: { id: 193, name: 'San Marino', short: 'SM' },
  194: { id: 194, name: 'Sao Tome and Principe', short: 'ST' },
  195: { id: 195, name: 'Saudi Arabia', short: 'SA' },
  196: { id: 196, name: 'Senegal', short: 'SN' },
  197: { id: 197, name: 'Serbia', short: 'RS' },
  198: { id: 198, name: 'Seychelles', short: 'SC' },
  199: { id: 199, name: 'Sierra Leone', short: 'SL' },
  200: { id: 200, name: 'Singapore', short: 'SG' },
  201: { id: 201, name: 'Sint Maarten (Dutch part)', short: 'SX' },
  202: { id: 202, name: 'Slovakia', short: 'SK' },
  203: { id: 203, name: 'Slovenia', short: 'SI' },
  204: { id: 204, name: 'Solomon Islands', short: 'SB' },
  205: { id: 205, name: 'Somalia', short: 'SO' },
  206: { id: 206, name: 'South Africa', short: 'ZA' },
  207: {
    id: 207,
    name: 'South Georgia and the South Sandwich Islands',
    short: 'GS',
  },
  208: { id: 208, name: 'South Sudan', short: 'SS' },
  209: { id: 209, name: 'Spain', short: 'ES' },
  210: { id: 210, name: 'Sri Lanka', short: 'LK' },
  211: { id: 211, name: 'Sudan', short: 'SD' },
  212: { id: 212, name: 'Suriname', short: 'SR' },
  213: { id: 213, name: 'Svalbard and Jan Mayen', short: 'SJ' },
  214: { id: 214, name: 'Swaziland', short: 'SZ' },
  215: { id: 215, name: 'Sweden', short: 'SE' },
  216: { id: 216, name: 'Switzerland', short: 'CH' },
  217: { id: 217, name: 'Syrian Arab Republic', short: 'SY' },
  218: { id: 218, name: 'Taiwan', short: 'TW' },
  219: { id: 219, name: 'Tajikistan', short: 'TJ' },
  220: { id: 220, name: 'Tanzania', short: 'TZ' },
  221: { id: 221, name: 'Thailand', short: 'TH' },
  222: { id: 222, name: 'Timor-Leste', short: 'TL' },
  223: { id: 223, name: 'Togo', short: 'TG' },
  224: { id: 224, name: 'Tokelau', short: 'TK' },
  225: { id: 225, name: 'Tonga', short: 'TO' },
  226: { id: 226, name: 'Trinidad and Tobago', short: 'TT' },
  227: { id: 227, name: 'Tunisia', short: 'TN' },
  228: { id: 228, name: 'Turkey', short: 'TR' },
  229: { id: 229, name: 'Turkmenistan', short: 'TM' },
  230: { id: 230, name: 'Turks and Caicos Islands', short: 'TC' },
  231: { id: 231, name: 'Tuvalu', short: 'TV' },
  232: { id: 232, name: 'Uganda', short: 'UG' },
  233: { id: 233, name: 'Ukraine', short: 'UA' },
  234: { id: 234, name: 'United Arab Emirates', short: 'AE' },
  235: { id: 235, name: 'United Kingdom', short: 'GB' },
  236: { id: 236, name: 'United States', short: 'US' },
  237: { id: 237, name: 'United States Minor Outlying Islands', short: 'UM' },
  238: { id: 238, name: 'Uruguay', short: 'UY' },
  239: { id: 239, name: 'Uzbekistan', short: 'UZ' },
  240: { id: 240, name: 'Vanuatu', short: 'VU' },
  241: { id: 241, name: 'Venezuela', short: 'VE' },
  242: { id: 242, name: 'Viet Nam', short: 'VN' },
  243: { id: 243, name: 'Virgin Islands, British', short: 'VG' },
  244: { id: 244, name: 'Virgin Islands, U.S.', short: 'VI' },
  245: { id: 245, name: 'Wallis and Futuna', short: 'WF' },
  246: { id: 246, name: 'Western Sahara', short: 'EH' },
  247: { id: 247, name: 'Yemen', short: 'YE' },
  248: { id: 248, name: 'Zambia', short: 'ZM' },
  249: { id: 249, name: 'Zimbabwe', short: 'ZW' },
}

const findByName = (name) =>
  Object.values(countries).find((country) => country.name === name)

const findByShort = (short) =>
  Object.values(countries).find((country) => country.short === short)

const findById = (id) => countries[id]

const nameById = (id) => {
  const country = findById(id)
  return !!country ? country.name : ''
}

export { countries as default, findByName, findByShort, findById, nameById }
