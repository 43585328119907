import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import Supercluster from 'supercluster'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { findByShort as findCountryLocationByShort } from '../../js/countryLocations'
import { findByName as findStateLocationByName } from '../../js/usStateLocations'
import CourseMarker from './CourseMarker'
import ClusterMarker from './ClusterMarker'
import Popup from './Popup'

const Marker = ({ children }) => children

class GroupsMap extends Component {
  constructor(props) {
    super(props)

    const { country, state } = this.props

    this.state = {
      country,
      state,
      groups: [], // Init as empty to trigger createSuperclusterState
      points: [],
      zoom: props.zoom,
      bounds: null,
      supercluster: null,
      popup: null,
      defaultZoom: this.getZoom(),
      defaultCenter: this.getCenter(),
    }

    this.mapRef = React.createRef()
  }

  static createSuperclusterState(groups) {
    console.log('createSuperclusterState', groups)
    const points = groups.map((group) => ({
      type: 'Feature',
      properties: { cluster: false, id: group.id, category: group.name },
      geometry: {
        type: 'Point',
        coordinates: [group.location.lng, group.location.lat],
      },
    }))

    const supercluster = new Supercluster({
      radius: 40,
      maxZoom: 20,
    })

    supercluster.load(points)

    return {
      groups,
      points,
      supercluster,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.groups.length !== nextProps.groups.length) {
      return GroupsMap.createSuperclusterState(nextProps.groups)
    }
    return null
  }

  getZoom = () => {
    const search = queryString.parse(window.location.search)
    if (search.zoom) {
      return parseFloat(search.zoom)
    }

    return this.props.zoom
  }

  getCenter() {
    const search = queryString.parse(window.location.search)
    if (search.center) {
      const latLng = search.center.split(',')
      return {
        lat: parseFloat(latLng[0]),
        lng: parseFloat(latLng[1]),
      }
    }

    const { country, state } = this.state || this.props

    if (state) {
      const location = findStateLocationByName(state.name)
      return {
        lat: location.latitude,
        lng: location.longitude,
      }
    }

    if (country) {
      const location = findCountryLocationByShort(country.short)
      return {
        lat: location.latitude,
        lng: location.longitude,
      }
    }

    return {
      lat: 0,
      lng: 0,
    }
  }

  setZoom(zoom) {
    this.setState({ zoom })
  }

  setBounds(bounds) {
    this.setState({ bounds })
  }

  onCourseGroupClicked = (cluster) => {
    const { id } = cluster.properties
    this.props.onCourseGroupClicked(
      this.state.groups.find((group) => group.id === id)
    )
  }

  onClusterClicked = (cluster) => {
    const children = this.state.supercluster.getChildren(cluster.id)
    console.log('Click onClusterClicked', cluster, children)
    this.setState({
      popup: {
        cluster,
        children,
      },
    })
  }

  renderMarkers() {
    const { supercluster, bounds, zoom, points } = this.state
    console.log('renderMarkers', this.state)
    if (!supercluster || !bounds || bounds.length === 0) return null

    try {
      const clusters = supercluster.getClusters(bounds, zoom)
      return clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const {
          cluster: isCluster,
          point_count: pointCount,
        } = cluster.properties

        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              lat={latitude}
              lng={longitude}
            >
              <ClusterMarker
                cluster={cluster}
                supercluster={supercluster}
                mapRef={this.mapRef}
                pointCount={pointCount}
                pointsLength={points.length}
                onClickCluster={(cluster) => this.onClusterClicked(cluster)}
              />
            </Marker>
          )
        }

        return (
          <Marker
            key={`course-${cluster.properties.id}`}
            lat={latitude}
            lng={longitude}
          >
            <CourseMarker
              cluster={cluster}
              onClick={() => this.onCourseGroupClicked(cluster)}
            />
          </Marker>
        )
      })
    } catch (e) {
      return null
    }
  }

  render() {
    const { popup, defaultCenter, defaultZoom } = this.state
    console.log('render', this.state)

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          center={this.getCenter()}
          zoom={this.getZoom()}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map }) => {
            this.mapRef.current = map
          }}
          onChange={({ center, zoom, bounds }) => {
            console.log('onChange', center, zoom, bounds, this.props.history)
            const currentSearch = this.props.history.location.search
            const newSearch = `?zoom=${zoom}&center=${center.lat},${center.lng}`
            if (newSearch !== currentSearch) {
              this.props.history.push(
                this.props.history.location.pathname + newSearch
              )
            }
            this.setZoom(zoom)
            this.setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ])
          }}
          options={{
            mapTypeControl: true,
          }}
        >
          {this.renderMarkers()}
          {popup && (
            <Marker
              key="popup"
              lat={popup.cluster.geometry.coordinates[1]}
              lng={popup.cluster.geometry.coordinates[0]}
            >
              <Popup
                clusters={popup.children}
                onClickCourse={this.onCourseGroupClicked}
              />
            </Marker>
          )}
        </GoogleMapReact>
      </div>
    )
  }
}

GroupsMap.defaultProps = {
  zoom: 5.5,
}

export default withRouter(GroupsMap)
